import React, { ReactElement, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ISO6391 from 'iso-639-1';
import ISO31661 from 'iso-3166-1';
import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faBold,
  faItalic,
  faUnderline
} from '@fortawesome/free-solid-svg-icons';
import countryCodes from '../utils/isoCodes';
import phoneCodesWithFlags from '../utils/countries';

export type Options = {
  value: string | number;
  label: any | ReactElement;
  activeLabel?: any | ReactElement;
  icon?: string | ReactElement;
};

type Country = {
  value: string;
  label: string;
};

type iOptions = {
  animationTypeOptions: Options[];
  activeAnimationTypeOptions: Options[];
  animationDurationOptions: Options[];
  borderStyleOptions: Options[];
  borderWidthOptions: Options[];
  euCountryCodes: string[];
  marginOptions: Options[];
  highMarginOptions: Options[];
  getLanguageByCode: (code: string) => string;
  borderPositionOptions: Options[];
  newAlignmentOptions: Options[];
  newBorderRadiusOptions: Options[];
  timeOptions: Options[];
  newBorderPositionOptions: Options[];
  twoAlignmentOptions: Options[];
  countryOptions: Options[];
  phoneCodesWithFlagsOptions: Options[];
  positionOptions: Options[];
  verticalPositionOptions: Options[];
  newBorderWidthOptions: Options[];
  textDecorationOptions: Options[];
  langsOptions: Options[];
  isoCodes: Options[];
  triviaGamesDelayOptions: Options[];
  textGameQuestionPosition: Options[];
  buttonAnimationOptions: Options[];
  imageSizeOptions: Options[];
  imagePositionOptions: Options[];
  fullBorderPositionOptions: Options[];
};

type ISOCountry = {
  country: string;
  alpha2: string;
  alpha3: string;
  numeric: string;
};

type PhoneOption = {
  name: string;
  flag: string;
  code: string;
  dial_code: string;
};

const useOptions = (): iOptions => {
  const triviaGamesDelayOptions = [
    { label: '1 second', value: 1000 },
    { label: '2 seconds', value: 2000 },
    { label: '3 seconds', value: 3000 },
    { label: '4 seconds', value: 4000 },
    { label: '5 seconds', value: 5000 },
    { label: '6 seconds', value: 6000 }
  ];

  const animationDurationOptions = [
    {
      value: 500,
      label: 'Fast'
    },
    {
      value: 1000,
      label: 'Medium'
    },
    {
      value: 1500,
      label: 'Slow'
    }
  ];

  const imageSizeOptions = useMemo(
    () => [
      {
        value: 'cover',
        label: 'Cover'
      },
      {
        value: 'contain',
        label: 'Contain'
      }
    ],
    []
  );

  const imagePositionOptions = useMemo(
    () => [
      {
        value: 'top',
        label: 'Top'
      },
      {
        value: 'left',
        label: 'Left'
      },
      {
        value: 'right',
        label: 'Right'
      },
      {
        value: 'bottom',
        label: 'Bottom'
      },
      {
        value: 'center',
        label: 'Center'
      }
    ],
    []
  );

  const animationTypeOptions = [
    {
      value: '',
      label: 'None'
    },
    {
      value: 'flipInX',
      label: 'flip over'
    },
    {
      value: 'bounce',
      label: 'bounce'
    },
    {
      value: 'pulse',
      label: 'pulse'
    },
    {
      value: 'zoomIn',
      label: 'zoom in'
    },
    {
      value: 'fadeIn',
      label: 'fade-in'
    },
    {
      value: 'fadeInDown',
      label: 'fade-in down'
    },
    {
      value: 'fadeInUp',
      label: 'fade-in up'
    },
    {
      value: 'fadeInRight',
      label: 'fade-in right'
    },
    {
      value: 'fadeInLeft',
      label: 'fade-in left'
    },
    {
      value: 'backInDown',
      label: 'back-in down'
    },
    {
      value: 'backInLeft',
      label: 'back-in left'
    },
    {
      value: 'backInRight',
      label: 'back-in right'
    },
    {
      value: 'backInUp',
      label: 'back-in up'
    }
  ];

  const activeAnimationTypeOptions = [
    {
      value: '',
      label: 'None'
    },
    {
      value: 'flipInX',
      label: 'flip over'
    },
    {
      value: 'bounce',
      label: 'bounce'
    },
    {
      value: 'pulse',
      label: 'pulse'
    },
    {
      value: 'zoomIn',
      label: 'zoom in'
    },
    {
      value: 'fadeIn',
      label: 'fade-in'
    }
  ];

  const borderStyleOptions = [
    {
      value: 'solid',
      label: 'Solid'
    },
    {
      value: 'dashed',
      label: 'Dashed'
    },
    {
      value: 'dotted',
      label: 'Dotted'
    }
  ];

  const borderPositionOptions = [
    {
      value: 'right',
      label: 'Right'
    },
    {
      value: 'left',
      label: 'Left'
    },
    {
      value: 'top',
      label: 'Top'
    },
    {
      value: 'bottom',
      label: 'Bottom'
    },
    {
      value: 'all',
      label: 'All'
    }
  ];

  const borderWidthOptions = Array.from({ length: 41 }).map((_item: any, idx: number) => ({
    label: `${idx}px`,
    value: idx
  }));
  const marginOptions = Array.from({ length: 31 }).map((_item: any, idx: number) => ({
    label: `${idx}px`,
    value: idx
  }));
  const highMarginOptions = Array.from({ length: 51 }).map((_item: any, idx: number) => ({
    label: `${idx}px`,
    value: idx
  }));

  const positionOptions = [
    {
      value: 'left',
      label: 'Left'
    },
    {
      value: 'center',
      label: 'Center'
    },
    {
      value: 'right',
      label: 'Right'
    }
  ];

  const verticalPositionOptions = [
    {
      value: 'top',
      label: 'Top'
    },
    {
      value: 'center',
      label: 'Center'
    },
    {
      value: 'bottom',
      label: 'Bottom'
    }
  ];

  const newBorderWidthOptions = [
    {
      value: 1,
      label: React.createElement('div', {
        style: { minWidth: 10, height: 1, display: 'inline-block', background: '#272727' }
      }),
      activeLabel: React.createElement('div', {
        style: { minWidth: 10, height: 1, display: 'inline-block', background: '#ffffff' }
      })
    },
    {
      value: 2,
      label: React.createElement('div', {
        style: { minWidth: 10, height: 2, display: 'inline-block', background: '#272727' }
      }),
      activeLabel: React.createElement('div', {
        style: { minWidth: 10, height: 2, display: 'inline-block', background: '#ffffff' }
      })
    }
  ];

  const newBorderPositionOptions = [
    {
      value: 'all',
      label: React.createElement('div', {
        style: { minWidth: 12, height: 12, display: 'inline-block', border: '2px solid #545454' }
      }),
      activeLabel: React.createElement('div', {
        style: { minWidth: 12, height: 12, display: 'inline-block', border: '2px solid #ffffff' }
      })
    },
    {
      value: 'bottom',
      label: React.createElement('div', {
        style: {
          minWidth: 12,
          height: 12,
          display: 'inline-block',
          border: '1px solid #DFDFDF',
          borderBottom: '2px solid #545454'
        }
      }),
      activeLabel: React.createElement('div', {
        style: {
          minWidth: 12,
          height: 12,
          display: 'inline-block',
          border: '1px solid #ffffff',
          borderBottom: '2px solid #ffffff'
        }
      })
    },
    {
      value: 'left',
      label: React.createElement('div', {
        style: {
          minWidth: 12,
          height: 12,
          display: 'inline-block',
          border: '1px solid #DFDFDF',
          borderLeft: '2px solid #545454'
        }
      }),
      activeLabel: React.createElement('div', {
        style: {
          minWidth: 12,
          height: 12,
          display: 'inline-block',
          border: '1px solid #ffffff',
          borderLeft: '2px solid #ffffff'
        }
      })
    }
  ];

  const fullBorderPositionOptions = [
    ...newBorderPositionOptions,
    {
      value: 'top',
      label: React.createElement('div', {
        style: { minWidth: 12, height: 12, display: 'inline-block', borderTop: '2px solid #545454' }
      }),
      activeLabel: React.createElement('div', {
        style: { minWidth: 12, height: 12, display: 'inline-block', borderTop: '2px solid #ffffff' }
      })
    },
    {
      value: 'right',
      label: React.createElement('div', {
        style: {
          minWidth: 12,
          height: 12,
          display: 'inline-block',
          border: '1px solid #DFDFDF',
          borderRight: '2px solid #545454'
        }
      }),
      activeLabel: React.createElement('div', {
        style: {
          minWidth: 12,
          height: 12,
          display: 'inline-block',
          border: '1px solid #ffffff',
          borderRight: '2px solid #ffffff'
        }
      })
    }
  ];

  const newAlignmentOptions = [
    {
      value: 'left',
      label: React.createElement(FontAwesomeIcon, { icon: faAlignLeft, style: { color: '#272727' } }),
      activeLabel: React.createElement(FontAwesomeIcon, { icon: faAlignLeft, style: { color: '#ffffff' } })
    },
    {
      value: 'center',
      label: React.createElement(FontAwesomeIcon, { icon: faAlignCenter, style: { color: '#272727' } }),
      activeLabel: React.createElement(FontAwesomeIcon, { icon: faAlignCenter, style: { color: '#ffffff' } })
    },
    {
      value: 'right',
      label: React.createElement(FontAwesomeIcon, { icon: faAlignRight, style: { color: '#272727' } }),
      activeLabel: React.createElement(FontAwesomeIcon, { icon: faAlignRight, style: { color: '#ffffff' } })
    }
  ];

  const twoAlignmentOptions = [
    {
      value: 'left',
      label: React.createElement(FontAwesomeIcon, { icon: faAlignLeft, style: { color: '#272727' } }),
      activeLabel: React.createElement(FontAwesomeIcon, { icon: faAlignLeft, style: { color: '#ffffff' } })
    },
    {
      value: 'center',
      label: React.createElement(FontAwesomeIcon, { icon: faAlignCenter, style: { color: '#272727' } }),
      activeLabel: React.createElement(FontAwesomeIcon, { icon: faAlignCenter, style: { color: '#ffffff' } })
    }
  ];

  const textDecorationOptions = [
    {
      value: 'bold',
      label: React.createElement(FontAwesomeIcon, { icon: faBold, style: { color: '#272727' } }),
      activeLabel: React.createElement(FontAwesomeIcon, { icon: faBold, style: { color: '#ffffff' } })
    },
    {
      value: 'underline',
      label: React.createElement(FontAwesomeIcon, { icon: faUnderline, style: { color: '#272727' } }),
      activeLabel: React.createElement(FontAwesomeIcon, { icon: faUnderline, style: { color: '#ffffff' } })
    },
    {
      value: 'italic',
      label: React.createElement(FontAwesomeIcon, { icon: faItalic, style: { color: '#272727' } }),
      activeLabel: React.createElement(FontAwesomeIcon, { icon: faItalic, style: { color: '#ffffff' } })
    }
  ];

  const borderRadius = [
    {
      view: 0,
      value: 0
    },
    {
      view: 1,
      value: 5
    },
    {
      view: 3,
      value: 10
    },
    {
      view: 6,
      value: 15
    }
  ];

  const newBorderRadiusOptions = borderRadius.map((item: { view: number; value: number }) => ({
    value: item.value,
    label: React.createElement('div', {
      style: {
        minWidth: 12,
        height: 12,
        display: 'inline-block',
        borderTop: '2px solid #545454',
        borderBottom: '2px solid #545454',
        borderLeft: '2px solid #545454',
        borderRadius: `${item.view}px 0 0 ${item.view}px`,
        borderRight: '0 solid transparent'
      }
    }),
    activeLabel: React.createElement('div', {
      style: {
        minWidth: 12,
        height: 12,
        display: 'inline-block',
        borderTop: '2px solid #ffffff',
        borderBottom: '2px solid #ffffff',
        borderLeft: '2px solid #ffffff',
        borderRadius: `${item.view}px 0 0 ${item.view}px`,
        borderRight: '0 solid transparent'
      }
    })
  }));

  const timeOptions = Array.from({ length: 24 }).map((_item: any, idx: number) => ({
    label: `${idx < 10 ? '0' : ''}${idx}:00`,
    value: idx
  }));

  const isoCodes = countryCodes.map((item: Country) => ({ label: item.label, value: item.value.toLowerCase() }));

  const langsOptions = ISO6391.getAllCodes().map((code: string) => ({
    value: code,
    label: ISO6391.getName(code)
  }));

  const getLanguageByCode = (code: string) => ISO6391.getName(code);

  const countryOptions = ISO31661.all().map((item: ISOCountry) => ({ label: item.country, value: item.alpha2 }));

  const phoneCodesWithFlagsOptions = phoneCodesWithFlags.map((item: PhoneOption) => ({
    value: item.dial_code,
    label: item.flag + ' ' + item.name
  }));

  const euCountryCodes: string[] = [
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SL',
    'ES',
    'SE'
  ];

  const textGameQuestionPosition = useMemo(
    () => [
      {
        label: 'Top',
        value: 'top'
      },
      {
        label: 'Bottom',
        value: 'bottom'
      }
    ],
    []
  );

  const buttonAnimationOptions = useMemo(
    () => [
      { label: 'None', value: '' },
      { label: 'Moving border', value: 'border-line' },
      { label: 'Pulse', value: 'pulse' },
      { label: 'Glowing border', value: 'border-highlight' },
      { label: 'Inverse color', value: 'inverse-colors' }
    ],
    []
  );

  return {
    animationTypeOptions,
    animationDurationOptions,
    borderWidthOptions,
    langsOptions,
    marginOptions,
    highMarginOptions,
    phoneCodesWithFlagsOptions,
    isoCodes,
    countryOptions,
    newBorderWidthOptions,
    activeAnimationTypeOptions,
    newBorderRadiusOptions,
    timeOptions,
    getLanguageByCode,
    textDecorationOptions,
    newBorderPositionOptions,
    twoAlignmentOptions,
    euCountryCodes,
    newAlignmentOptions,
    borderPositionOptions,
    positionOptions,
    verticalPositionOptions,
    borderStyleOptions,
    triviaGamesDelayOptions,
    textGameQuestionPosition,
    buttonAnimationOptions,
    imageSizeOptions,
    imagePositionOptions,
    fullBorderPositionOptions
  };
};

export default useOptions;
