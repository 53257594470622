import { useDispatch, useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import {
  addCampaignList,
  clearCampaigns,
  deleteCampaignFromList,
  setCampaignList
} from '../../store/campaigns/actions';
import { campaignsListSelector, campaignsLoadedSelector, campaignsMetaSelector } from '../../store/campaigns/selectors';
import useApi from '../useApi';
import { useState } from 'react';
import { ResourceMeta } from '../../store/types';
import { ListCampaign } from '../../utils/type';
import { message, notification } from 'antd';
import useCurrentUser from '../../utils/hooks/useCurrentUser';

export type SearchCampaign = {
  id: number;
  slug: string;
  title: string;
  send_webhooks: boolean;
};

interface iCampaignsList {
  list: ListCampaign[];
  meta: ResourceMeta;
  getList: (page: number, limit: number, query: string, analytics?: boolean) => void;
  searchCampaigns: (query: string) => void;
  restoreCampaign: (campaignId: number) => void;
  suggestedCampaigns: SearchCampaign[];
  clearList: () => void;
  loaded: boolean;
}

type Props = {
  brandId?: number;
};

const useCampaignsList = ({ brandId = 0 }: Props): iCampaignsList => {
  const dispatch = useDispatch();
  const api = useApi();
  const { user } = useCurrentUser();
  const list = useSelector(campaignsListSelector);
  const meta = useSelector(campaignsMetaSelector);
  const loaded = useSelector(campaignsLoadedSelector);
  const [suggestedCampaigns, onChangeSuggestedCampaigns] = useState<SearchCampaign[]>([]);

  const getList = (page: number, limit: number, query: string, analytics: boolean = false) => {
    if (brandId < 1) return;
    api.getCampaignsWithAnalytics(brandId, page, limit, query).then((response: AxiosResponse) => {
      if (analytics) {
        if (page === 1) {
          dispatch(setCampaignList(response.data));
        } else {
          dispatch(addCampaignList(response.data));
        }
      } else {
        dispatch(setCampaignList(response.data));
      }
    });
  };

  const clearList = () => {
    dispatch(clearCampaigns());
  };

  const searchCampaigns = (query: string) => {
    if (!user.account.id) {
      return;
    }

    api.searchCampaigns(query).then((response: AxiosResponse) => {
      onChangeSuggestedCampaigns(response.data);
    });
  };

  const restoreCampaign = async (campaignId: number) => {
    try {
      await api.restoreCampaign(campaignId);
      dispatch(deleteCampaignFromList(campaignId));

      notification.success({
        message: 'Info',
        description: 'Campaign was successfully restored'
      });
    } catch (e) {
      message.error(e.response?.data.message);
    }
  };

  return {
    list,
    clearList,
    suggestedCampaigns,
    meta,
    searchCampaigns,
    restoreCampaign,
    getList,
    loaded
  };
};

export default useCampaignsList;
