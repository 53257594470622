import { UserAccountRoles, UserRoles } from '../enums';
import { AuthorizedUser } from '../type/users/authorizedUser.type';

export const hasFullAccess = (user: Partial<AuthorizedUser>) => {
  if (!user) {
    return false;
  }

  return (
    [UserRoles.ADMIN, UserRoles.SUPER_ADMIN].includes(user.role as UserRoles) ||
    [UserAccountRoles.ACCOUNT_OWNER, UserAccountRoles.ACCOUNT_ADMIN].includes(user.userAccountRole as UserAccountRoles)
  );
};
