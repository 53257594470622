import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  accountSelector,
  activeBrandIdSelector,
  authSelector,
  brandsForFilterSelector,
  userSelector
} from '../../../store/auth/selectors';
import { Account, BrandForFilter } from '../../type';
import { AccountType, PaymentTypeEnum, UserAccountRoles, UserRoles } from '../../enums';
import { hasFullAccess } from '../../helpers/hasFullAccess';
import { AuthorizedUser } from '../../type/users/authorizedUser.type';

type UseUser = {
  user: AuthorizedUser;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  withoutBrands: boolean;
  hasAccess: boolean;
  activeBrandId: number | undefined;
  showLeadsMessage: boolean;
  account: Account;
  noAccessToCampaigns: boolean;
  licenseType: PaymentTypeEnum | undefined;
  hasActiveLicense: boolean;
  paidAccount: boolean;
  hasPaidLicense: boolean;
};

const useCurrentUser = (): UseUser => {
  const auth = useSelector(authSelector);
  const user = useSelector(userSelector);
  const activeBrandId = useSelector(activeBrandIdSelector);
  const brandsForFilter = useSelector(brandsForFilterSelector);
  const account: Account = useSelector(accountSelector);
  const isAdmin = useMemo(() => [UserRoles.ADMIN, UserRoles.SUPER_ADMIN].includes(user.role), [user.role]);
  const isSuperAdmin = useMemo(() => user.role === UserRoles.SUPER_ADMIN, [user.role]);

  const withoutBrands = useMemo(() => {
    if (auth.authorized && user.id && user.userAccountRole === UserAccountRoles.TEAM_MEMBER) return false;
    return Boolean(auth.authorized && user.id && user.account.brands.length < 1 && hasFullAccess(user));
  }, [auth.authorized, user]);

  const showLeadsMessage = false;

  const hasAccess = useMemo(() => {
    const teamMemberActiveBrand = brandsForFilter.find((brand: BrandForFilter) => brand.id === activeBrandId);
    return user.userAccountRole === UserAccountRoles.TEAM_MEMBER ? Boolean(teamMemberActiveBrand?.fullAccess) : true;
  }, [user, brandsForFilter, activeBrandId]);

  const noAccessToCampaigns = useMemo(() => {
    return user.userAccountRole === UserAccountRoles.TEAM_MEMBER && brandsForFilter.length < 1;
  }, [user, brandsForFilter]);

  const licenseType: PaymentTypeEnum | undefined = account.paymentLicenses && account.paymentLicenses[0]?.type;
  const hasPaidLicense: boolean = [
    PaymentTypeEnum.SelfService,
    PaymentTypeEnum.Enterprise,
    PaymentTypeEnum.Custom
  ].includes(licenseType as PaymentTypeEnum);

  const hasActiveLicense =
    [AccountType.STAFF, AccountType.FREE].includes(user.account.type) || account.has_active_license;

  const paidAccount = account.paid || account.type !== AccountType.STANDARD;

  return {
    hasAccess,
    noAccessToCampaigns,
    withoutBrands,
    account,
    showLeadsMessage,
    activeBrandId,
    user,
    isAdmin,
    isSuperAdmin,
    licenseType,
    hasActiveLicense,
    paidAccount,
    hasPaidLicense
  };
};

export default useCurrentUser;
