import { createSelector } from 'reselect';
import { Store } from '../types';
import { AdminState } from './types';

export const adminSelector = (store: Store) => store.admin;
export const adminCampaignsSelector = createSelector(adminSelector, (auth: AdminState) =>
  auth.campaigns.asMutable({ deep: true })
);
export const adminBrandsSelector = createSelector(adminSelector, (auth: AdminState) =>
  auth.brands.asMutable({ deep: true })
);
export const adminBrandSelector = createSelector(adminSelector, (auth: AdminState) =>
  auth.activeBrand.asMutable({ deep: true })
);
export const adminAccountsSelector = createSelector(adminSelector, (auth: AdminState) =>
  auth.accounts.asMutable({ deep: true })
);
export const adminAccountSelector = createSelector(adminSelector, (auth: AdminState) =>
  auth.account.asMutable({ deep: true })
);
export const adminPlayersSelector = createSelector(adminSelector, (auth: AdminState) =>
  auth.players.asMutable({ deep: true })
);
export const newsPostsSelector = createSelector(adminSelector, (auth: AdminState) =>
  auth.newsPosts.asMutable({ deep: true })
);
export const newsPostSelector = createSelector(adminSelector, (auth: AdminState) =>
  auth.newsPost.asMutable({ deep: true })
);
export const adminAdminUsersSelector = createSelector(adminSelector, (auth: AdminState) =>
  auth.adminUsers.asMutable({ deep: true })
);
export const adminAdminUserSelector = createSelector(adminSelector, (auth: AdminState) =>
  auth.adminUser.asMutable({ deep: true })
);
export const adminInvitationsSelector = createSelector(adminSelector, (auth: AdminState) =>
  auth.adminInvitations.asMutable({ deep: true })
);
export const adminGamesSelector = createSelector(adminSelector, (auth: AdminState) =>
  auth.games.asMutable({ deep: true })
);
export const adminGameSelector = createSelector(adminSelector, (auth: AdminState) =>
  auth.game.asMutable({ deep: true })
);
export const adminGameTagsSelector = createSelector(adminSelector, (auth: AdminState) =>
  auth.gameTags.asMutable({ deep: true })
);
export const adminGameTagSelector = createSelector(adminSelector, (auth: AdminState) =>
  auth.gameTag.asMutable({ deep: true })
);
export const adminCampaignTemplatesSelector = createSelector(adminSelector, (auth: AdminState) =>
  auth.campaignTemplates.asMutable({ deep: true })
);
export const adminCampaignTemplateSelector = createSelector(adminSelector, (auth: AdminState) =>
  auth.campaignTemplate.asMutable({ deep: true })
);
export const adminCouponsListSelector = createSelector(adminSelector, (auth: AdminState) =>
  auth.coupons.asMutable({ deep: true })
);
export const adminCouponSelector = createSelector(adminSelector, (auth: AdminState) =>
  auth.coupon.asMutable({ deep: true })
);
export const adminAccountsCountries = createSelector(adminSelector, (auth: AdminState) =>
  auth.accountsCountries.asMutable({ deep: true })
);
export const adminAccountStatistics = createSelector(adminSelector, (auth: AdminState) =>
  auth.accountStatistics.asMutable({ deep: true })
);
export const adminAccountJoined = createSelector(adminSelector, (auth: AdminState) =>
  auth.accountsJoined.asMutable({ deep: true })
);
export const adminCampaignStatistics = createSelector(adminSelector, (auth: AdminState) =>
  auth.campaignsStatistics.asMutable({ deep: true })
);
