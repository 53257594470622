import { ReactElement } from 'react';
import { Modal } from 'antd';

type Params = {
  title?: string | ReactElement;
  okText?: string;
  onOk?: () => void;
  onCancel?: () => void;
};

const ModalConfirm = (params: Params) => {
  Modal.confirm({
    ...params,
    //@ts-ignore
    cancelButtonProps: { 'cypress-id': 'modal-confirm-cancel' },
    //@ts-ignore
    okButtonProps: { 'cypress-id': 'modal-confirm-ok' }
  });
};

export default ModalConfirm;
