import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import {
  DeleteMediaFilePayloadType,
  MediaFile,
  MediaFilePayloadType,
  UpdateMediaFilePayloadType
} from '../../utils/type';
import { commonSelector } from '../../store/common/selectors';
import { CommonStoreEnum } from '../../store/common/types';
import { useErrors } from '../../utils/hooks';
import useApi from '../useApi';
import { MediaFilesListOrderingEnum, MediaFileTypesEnum, Sorting } from '../../utils/enums';
import { queryToString } from '../../utils/query';
import { Pagination } from '../../store/types';
import { DEFAULT_PAGINATION } from '../../utils/const';
import { dataToFormData } from '../../utils/helpers/dataToFormData';
import axios, { CancelTokenSource } from 'axios';
import { GetMediaFilesListPayloadType } from '../../utils/type/mediaFiles/getMediaFilesListPayload.type';
import { activeBrandIdSelector } from '../../store/auth/selectors';
import { notification } from 'antd';
import { campaignSelector } from '../../store/campaign/selectors';
import {
  addItemInPagination,
  removeItemInPagination,
  setCommonField,
  updateCommonArrayField
} from '../../store/common';

type BusyState = {
  recent: boolean;
  create: boolean;
  update: boolean;
  list: boolean;
  getByKey: boolean;
  delete: null | number[];
};

export type iMediaFiles = {
  mediaFiles: Pagination<MediaFile>;
  recentMediaFiles: Pagination<MediaFile>;
  getMediaFilesList: (page: number, payload: GetMediaFilesListPayloadType, limit?: number) => void;
  getRecentMediaFiles: (page: number, file_type: MediaFileTypesEnum) => void;
  createMediaFile: (data: MediaFilePayloadType, addToList: boolean) => Promise<MediaFile | null>;
  deleteMediaFile: (ids: number[]) => Promise<boolean>;
  updateMediaFiles: (data: UpdateMediaFilePayloadType) => Promise<boolean>;
  clearMediaFiles: () => void;
  busy: BusyState;
  isUploading: boolean;
  progress: number;
  cancelUpload: () => void;
  newMediaFiles: MediaFile[];
  queriedMediaFile: boolean;
};

const useMediaFiles = (): iMediaFiles => {
  const dispatch = useDispatch();
  const api = useApi();
  const [progress, onChangeProgress] = useState<number>(0);
  const [source, onChangeCancelTokenSource] = useState<CancelTokenSource | undefined>(undefined);

  const mediaFiles: Pagination<MediaFile> = useSelector(
    commonSelector(CommonStoreEnum.MEDIA_FILES)
  ) as unknown as Pagination<MediaFile>;
  const recentMediaFiles: Pagination<MediaFile> = useSelector(
    commonSelector(CommonStoreEnum.RECENT_MEDIA_FILES)
  ) as unknown as Pagination<MediaFile>;
  const newMediaFiles: MediaFile[] = useSelector(
    commonSelector(CommonStoreEnum.NEW_MEDIA_FILES)
  ) as unknown as MediaFile[];
  const queriedMediaFile: boolean = useSelector(commonSelector(CommonStoreEnum.QUERIED_MEDIA_FILES));

  const brandId = useSelector(activeBrandIdSelector);
  const { brand } = useSelector(campaignSelector);

  const currentBrand = brand.id || brandId || 0;

  const [busy, setBusy] = useState<BusyState>({
    list: false,
    delete: null,
    update: false,
    recent: false,
    create: false,
    getByKey: false
  });

  const changeBusy = (data: Partial<BusyState>) => {
    setBusy((prevState) => ({ ...prevState, ...data }));
  };

  const { clearErrors, parseErrors } = useErrors();

  const onUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    onChangeProgress(percentCompleted);
  };

  const cancelUpload = () => {
    if (source) source.cancel();
  };

  const getRecentMediaFiles = async (page: number, file_type: MediaFileTypesEnum) => {
    changeBusy({ recent: true });
    clearErrors();

    const query = queryToString({
      sort: Sorting.DESC,
      order: MediaFilesListOrderingEnum.CreatedAt,
      brandId: currentBrand,
      file_type
    });

    try {
      const response = await api.getMediaFilesList(page, 16, query);
      dispatch(
        setCommonField(
          page === 1
            ? response.data
            : {
                meta: response.data,
                items: [...mediaFiles.items, ...response.data]
              },
          CommonStoreEnum.RECENT_MEDIA_FILES
        )
      );
    } catch (e) {
      parseErrors(e);
    }

    changeBusy({ recent: false });
  };

  const getMediaFilesList = async (page: number, data: GetMediaFilesListPayloadType, limit: number = 16) => {
    changeBusy({ list: true });
    clearErrors();

    const payload: GetMediaFilesListPayloadType = {
      sort: data.sort || Sorting.DESC,
      order: data.order || MediaFilesListOrderingEnum.CreatedAt,
      ...data,
      brandId: currentBrand
    };

    try {
      const response = await api.getMediaFilesList(page, limit, queryToString(payload));
      dispatch(setCommonField(response.data, CommonStoreEnum.MEDIA_FILES));
      dispatch(setCommonField(true, CommonStoreEnum.QUERIED_MEDIA_FILES));
    } catch (e) {
      parseErrors(e);
    }

    changeBusy({ list: false });
  };

  const createMediaFile = async (data: MediaFilePayloadType, addToList: boolean): Promise<MediaFile | null> => {
    changeBusy({ create: true });
    clearErrors();
    onChangeProgress(0);
    let newMediaFile: MediaFile | null = null;

    const source = axios.CancelToken.source();
    onChangeCancelTokenSource(source);

    const payload: MediaFilePayloadType = {
      ...data,
      brandId: currentBrand
    };

    const formData = dataToFormData(payload);

    try {
      const response = await api.createMediaFile(formData, { onUploadProgress, cancelToken: source.token });
      if (addToList) {
        dispatch(addItemInPagination(response.data, CommonStoreEnum.MEDIA_FILES));
      }

      dispatch(updateCommonArrayField([response.data], CommonStoreEnum.NEW_MEDIA_FILES));
      newMediaFile = response.data;
      onChangeProgress(0);
    } catch (e) {
      parseErrors(e);
    }

    changeBusy({ create: false });

    return newMediaFile;
  };

  const updateMediaFiles = async (data: UpdateMediaFilePayloadType) => {
    changeBusy({ update: true });
    let success = true;

    try {
      await api.updateMediaFiles(data);
      notification.success({
        message: 'Success',
        description: 'Media files updated'
      });
      dispatch(
        setCommonField(
          {
            ...mediaFiles,
            items: mediaFiles.items.map((item: MediaFile) =>
              data.mediaFileIds.includes(item.id) ? { ...item, categories: data.categories } : item
            )
          },
          CommonStoreEnum.MEDIA_FILES
        )
      );
      dispatch(
        setCommonField(
          {
            ...recentMediaFiles,
            items: recentMediaFiles.items.map((item: MediaFile) =>
              data.mediaFileIds.includes(item.id) ? { ...item, categories: data.categories } : item
            )
          },
          CommonStoreEnum.RECENT_MEDIA_FILES
        )
      );
    } catch (e) {
      parseErrors(e);
      success = false;
    }

    changeBusy({ update: false });
    return success;
  };

  const deleteMediaFile = async (ids: number[]) => {
    changeBusy({ delete: ids });
    clearErrors();
    let success = true;
    try {
      await api.deleteMediaFile({ mediaFileIds: ids } as DeleteMediaFilePayloadType);
      dispatch(removeItemInPagination(ids, CommonStoreEnum.MEDIA_FILES));
      dispatch(removeItemInPagination(ids, CommonStoreEnum.RECENT_MEDIA_FILES));
    } catch (e) {
      parseErrors(e);
      success = false;
    }

    changeBusy({ delete: null });
    return success;
  };

  const clearMediaFiles = () => {
    dispatch(setCommonField(DEFAULT_PAGINATION, CommonStoreEnum.MEDIA_FILES));
    dispatch(setCommonField(DEFAULT_PAGINATION, CommonStoreEnum.RECENT_MEDIA_FILES));
    dispatch(setCommonField(false, CommonStoreEnum.QUERIED_MEDIA_FILES));
  };

  return {
    recentMediaFiles,
    mediaFiles,
    getMediaFilesList,
    getRecentMediaFiles,
    clearMediaFiles,
    createMediaFile,
    deleteMediaFile,
    busy,
    isUploading: progress > 0,
    cancelUpload,
    progress,
    updateMediaFiles,
    newMediaFiles,
    queriedMediaFile
  };
};

export default useMediaFiles;
