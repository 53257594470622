import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import Link from '../link';
import Typo from '../typo';
import Icon, { IconType } from '../icon';
import styles from './white.module.css';

export type ListItem = {
  label: string;
  divider?: boolean;
  to?: string;
  value?: string | number;
  icon?: IconType;
  disabled?: boolean;
  onClick?: () => void;
  hide?: boolean;
  children?: any;
  selectable?: boolean;
};

type Props = {
  list: ListItem[];
  className?: string;
  disabled?: boolean;
  asSelect?: boolean;
  name?: string;
  icon?: IconType;
  triggerClassName?: string;
  label: string;
  clearLabel?: string;
  activeIcon?: boolean;
  clearable?: boolean;
  value?: string | number;
  onChange?: (value: any) => void;
};

type MenuProps = {
  item: ListItem;
  active: boolean;
  onClick: (value: string | number) => void;
};

const MenuItem: FunctionComponent<MenuProps> = ({ item, active, onClick }) => {
  const [hover, onChangeHover] = useState<boolean>(false);

  const body = useMemo(
    () => (
      <div className={styles.itemBody}>
        {item.icon ? <Icon className={styles.icon} icon={item.icon} active={hover ?? active} /> : null}
        <Typo type="p" className={styles.itemLabel}>
          {item.label}
        </Typo>
      </div>
    ),
    [item, hover, active]
  );

  const handleClick = () => {
    if (item.value) {
      onClick(item.value);
    }

    if (item.onClick) item.onClick();
  };

  return (
    <li
      role="button"
      className={cx({ [styles.divider]: item.divider })}
      onMouseLeave={() => onChangeHover(false)}
      onMouseOver={() => onChangeHover(true)}
      onClick={handleClick}
    >
      {item.to ? (
        <Link className={cx(styles.item, { [styles.activeItem]: active, [styles.hoverable]: item.to })} to={item.to}>
          {body}
        </Link>
      ) : (
        <div className={cx(styles.item, { [styles.activeItem]: active, [styles.hoverable]: item.value })}>{body}</div>
      )}
      {item.children}
    </li>
  );
};

const Dropdown: FunctionComponent<Props> = ({
  list,
  asSelect,
  clearLabel,
  clearable,
  icon,
  activeIcon,
  disabled,
  name,
  label,
  triggerClassName,
  onChange,
  value,
  className
}) => {
  const [show, onChangeShow] = useState<boolean>(false);

  const id = useMemo(() => `white-dropdown-${name}`, [name]);

  useEffect(() => {
    document.addEventListener('click', handleHide, true);
    return () => {
      document.removeEventListener('click', handleHide, true);
    };
  }, [show]);

  const handleHide = useCallback(
    (event: any) => {
      const inDropdown = event.target?.closest(`#${id}`);
      if (show && !inDropdown) {
        onChangeShow(false);
      }
      return;
    },
    [onChangeShow, id, show]
  );

  const onHide = useCallback(
    (value: string | number) => {
      if (onChange) onChange(value);
      onChangeShow(false);
    },
    [onChangeShow, onChange]
  );

  const chosen: ListItem | undefined = useMemo(
    () => list.find((item: ListItem) => item.value && item.value === value),
    [list, value]
  );

  const clear = useMemo(() => ({ value: '', label: clearLabel ?? label }), [label, clearLabel]);

  return (
    <div id={id} className={cx(styles.wrapper, className)}>
      <div
        className={cx(styles.active, triggerClassName, {
          [styles.disabled]: disabled,
          [styles.select]: asSelect,
          [styles.open]: show
        })}
        onClick={() => (disabled ? null : onChangeShow(true))}
      >
        {icon ? <Icon icon={icon} active={activeIcon} className={styles.icon} /> : null}
        <Typo type="p" className={styles.label}>
          {chosen?.label || label}
        </Typo>
        {asSelect ? <Icon icon="caret" className={styles.caret} /> : null}
      </div>
      {show ? (
        <div className={styles.listWrapper}>
          <ul className={styles.list}>
            {clearable ? <MenuItem item={clear} active={value === clear.value} onClick={onHide} /> : null}
            {list
              .filter((item) => !item.hide)
              .map((item: ListItem, idx: number) => (
                <MenuItem item={item} active={!!item.value && value === item.value} key={idx} onClick={onHide} />
              ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default Dropdown;
