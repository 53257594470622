import React, { FunctionComponent } from 'react';
import styles from './accountList.module.css';
import useCurrentUser from '../../../utils/hooks/useCurrentUser';
import useAuth from '../../../domain/useAuth';
import { AccountForFilterType } from '../../../utils/type/account/accountForFilter.type';
import cx from 'classnames';

const AccountList: FunctionComponent = () => {
  const { user } = useCurrentUser();
  const { switchAccount } = useAuth();

  const onChangeAccount = (id: number) => {
    switchAccount(id);
  };

  return (
    <>
      {user.userAccountsForFilter?.map((account: AccountForFilterType) => (
        <div
          key={account.id}
          className={cx(styles.option, { [styles.active]: user.accountId === account.id })}
          onClick={() => onChangeAccount(account.id)}
        >
          {account.company_name}
        </div>
      ))}
      <div className={styles.separator} />
    </>
  );
};

export default AccountList;
