import React, { Fragment, FunctionComponent, useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import WhiteDropDown, { ListItem } from '../../components/dropdown/white';
import Typo from '../../components/typo';
import useAuth from '../../domain/useAuth';
import Icon, { IconType } from '../../components/icon';
import Logo from '../logo';
import useUI from '../../domain/useUi';
import useBrand from '../../domain/useBrand';
import styles from './white.module.css';
import TicketModal from '../ticketModal';
import { Popover } from 'antd';
import { AccountType, UserAccountRoles, UserRoles } from '../../utils/enums';
import useCurrentUser from '../../utils/hooks/useCurrentUser';
import BrandForm from '../forms/brandCreation';
import Modal from '../../components/modal';
import { useDispatch, useSelector } from 'react-redux';
import { uiSelector } from '../../store/ui/selectors';
import { UIStoreEnum } from '../../store/ui/types';
import { setUIField } from '../../store/ui/actions';
import AccountList from './accountList';

type HeaderLinkType = {
  id: string;
  label?: string;
  icon?: IconType;
  onClick?: () => void;
  to?: string;
  className?: string;
};

const HeaderLink: FunctionComponent<{ link: HeaderLinkType }> = ({ link }) => {
  const location = useLocation();
  const [hover, onChangeHover] = useState<boolean>(false);
  const isActive = useMemo(() => {
    if (!link.to) return false;
    if (link.to === '/campaigns' && location.pathname !== '/campaigns') {
      return false;
    }

    return location.pathname.includes(link.to);
  }, [link, location]);

  const body = useMemo(
    () => (
      <Fragment>
        {link.icon ? <Icon className={styles.icon} icon={link.icon} active={isActive || hover} /> : null}{' '}
        {link.label ? (
          <Typo type="p" className={styles.label}>
            {link.label}
          </Typo>
        ) : null}
      </Fragment>
    ),
    [link, isActive, hover]
  );

  return link.to ? (
    <NavLink
      to={link.to}
      onClick={link.onClick}
      onMouseOver={() => onChangeHover(true)}
      onMouseLeave={() => onChangeHover(false)}
      className={cx(styles.link, { [styles.onlyIcon]: link.icon && !link.label, [styles.activeLink]: isActive })}
      cypress-id={`header-link-${link.id}`}
    >
      {body}
    </NavLink>
  ) : (
    <div
      onClick={link.onClick}
      onMouseOver={() => onChangeHover(true)}
      onMouseLeave={() => onChangeHover(false)}
      className={cx(styles.link, { [styles.onlyIcon]: link.icon && !link.label })}
      cypress-id={`header-link-${link.id}`}
    >
      {body}
    </div>
  );
};

const WhiteHeader: FunctionComponent = () => {
  const [showMoreOptions, onShowMoreOptions] = useState<boolean>(false);
  const { sidebarType, sidebar, showTicket, tinySidebar, clearSidebar, setShowTicket } = useUI();
  const { logout } = useAuth();
  const { user, activeBrandId, withoutBrands, isAdmin, account } = useCurrentUser();
  const { brandsForHeader, onSelectSettingsBrand, brand } = useBrand();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const showEditBrand: boolean = useSelector(uiSelector(UIStoreEnum.SHOW_ACTIVE_BRAND_EDIT_MODAL));

  const closeEditBrand = () => {
    dispatch(setUIField(false, UIStoreEnum.SHOW_ACTIVE_BRAND_EDIT_MODAL));
  };

  const userLinks: HeaderLinkType[] = useMemo(
    () => [
      {
        id: 'campaigns',
        label: 'Campaigns',
        icon: 'campaigns',
        to: '/campaigns'
      },
      {
        id: 'analytics',
        label: 'Analytics',
        icon: 'analytics',
        to: '/analytics'
      },
      {
        id: 'settings',
        label: ' Adact Academy',
        icon: 'book',
        to: '/academy',
        className: styles.book
      }
    ],
    []
  );

  const adminLinks: HeaderLinkType[] = useMemo(
    () => [
      {
        id: 'admin-campaigns',
        label: 'Campaigns',
        to: '/admin/campaigns'
      },
      {
        id: 'admin-accounts',
        label: 'Accounts',
        to: '/admin/accounts'
      },
      {
        id: 'admin-brands',
        label: 'Brands',
        to: '/admin/brands'
      },
      {
        id: 'admin-players',
        label: 'Players',
        to: '/admin/players'
      },
      {
        id: 'admin-embed-script',
        label: 'Embed script',
        to: '/admin/embed'
      },
      {
        id: 'admin-news',
        label: 'News',
        to: '/admin/news'
      },
      {
        id: 'admin-admins',
        label: 'Admins',
        to: '/admin/admins'
      },
      {
        id: 'admin-games',
        label: 'Games',
        to: '/admin/games'
      },
      {
        id: 'admin-templates',
        label: 'Templates',
        to: '/admin/templates'
      },
      {
        id: 'admin-customer-success',
        label: 'Customer success',
        to: '/admin/customer-success'
      },
      {
        id: 'admin-licenses',
        label: 'Account Licenses',
        to: '/admin/licenses'
      },
      {
        id: 'admin-notifications',
        label: 'Notifications',
        to: '/admin/notifications'
      },
      {
        id: 'tokens',
        label: 'Tokens',
        to: '/admin/campaign-unlock'
      },
      {
        id: 'courses',
        label: 'Courses',
        to: '/admin/courses'
      }
    ],
    []
  );

  const settingsLinks: ListItem[] = useMemo(() => {
    const links: ListItem[] = [
      {
        label: 'Your Accounts',
        children: <AccountList />,
        hide: user.userAccountsForFilter.length < 2
      },
      {
        value: 'account-subscription',
        label: 'Account subscription',
        icon: 'plan',
        to: '/settings/subscription',
        disabled: account.type !== AccountType.STANDARD
      },
      {
        value: 'account-settings',
        label: 'Account settings',
        icon: 'cog',
        to: '/settings/account'
      },
      {
        value: 'brand-settings',
        label: 'Brand settings',
        icon: 'brand',
        to: '/settings/brand',
        disabled: user.userAccountRole === UserAccountRoles.TEAM_MEMBER
      },
      {
        value: 'account-members',
        label: 'Team members settings',
        icon: 'members',
        to: '/settings/members'
      },
      {
        value: 'account-integrations',
        label: 'Api central',
        icon: 'integration',
        to: '/settings/api-central'
      },
      {
        value: 'my-domain',
        label: 'My domain',
        icon: 'campaigns',
        to: '/settings/domain-integrations'
      },
      {
        value: 'crm-integrations',
        label: 'CRM integrations',
        icon: 'book',
        to: '/settings/crm-integrations'
      }
    ];

    return links.filter((link: ListItem) => !link.disabled);
  }, [account.type, user.role]);

  const disabledBrandsDropdown = useMemo(() => location.pathname.includes('settings'), [location]);

  const onSelectBrand = useCallback(
    (id: string | number) => {
      if (['create-brand', 'edit-brand'].includes(id as any)) return;
      onSelectSettingsBrand(id);
      if (disabledBrandsDropdown) history.push(`/settings/brand?id=${id}`);
    },
    [disabledBrandsDropdown, history, onSelectSettingsBrand]
  );

  const links = useMemo(() => (isAdmin ? adminLinks : userLinks), [isAdmin, userLinks, adminLinks]);

  const onClickShowLandModal = useCallback(() => {
    setShowTicket({ show: true });
    onShowMoreOptions(false);
  }, []);

  const onClickShowFAQ = useCallback(() => {
    onShowMoreOptions(false);
    window.open('https://help.adact.me/', '_blank');
  }, []);

  const moreOptions = useMemo(
    () => (
      <div className={styles.popoverWrapper}>
        <button className={styles.popoverBtn} onClick={onClickShowFAQ} name="campaign-add-language">
          Check FAQ
        </button>
        <button className={styles.popoverBtn} onClick={onClickShowLandModal} name="campaign-duplicate">
          Create a support ticket
        </button>
      </div>
    ),
    [onClickShowFAQ, onClickShowLandModal]
  );

  if (clearSidebar || (!user.accountId && user.role === UserRoles.STANDARD_USER)) {
    return (
      <div className={styles.white}>
        <div className={styles.container}>
          <Logo className={styles.whiteLogoWrapper} />
          <div className={styles.menus}></div>
          <div className={styles.rightSide}>
            <ul className={styles.list}>
              <li>
                <Popover
                  className={styles.popover}
                  overlayClassName={styles.popoverOverlay}
                  placement="bottomRight"
                  visible={showMoreOptions}
                  onVisibleChange={onShowMoreOptions}
                  content={moreOptions}
                  trigger="click"
                >
                  <HeaderLink
                    link={{ onClick: () => onShowMoreOptions(true), label: 'Need help?', icon: 'help', id: 'help' }}
                  />
                </Popover>
              </li>
              <li className={styles.logoutLi}>
                <HeaderLink link={{ id: 'logout', icon: 'logout', onClick: logout }} />
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={cx(
          styles.white,
          { [styles.withSidebar]: sidebar, [styles.tinySidebar]: tinySidebar },
          styles[`withSidebar-${sidebarType}`]
        )}
      >
        <div className={styles.container}>
          {!sidebar && <Logo to="/campaigns" className={styles.whiteLogoWrapper} />}
          <div className={styles.menus}>
            <ul className={styles.list}>
              {(isAdmin || !withoutBrands) &&
                links.map((link: HeaderLinkType) => (
                  <li key={link.id} className={link.className}>
                    <HeaderLink link={link} />
                  </li>
                ))}
            </ul>
          </div>
          <div className={styles.rightSide}>
            <ul className={styles.list}>
              {brandsForHeader.length > 0 && !isAdmin && (
                <li>
                  <WhiteDropDown
                    name="choose-brand"
                    icon="brand"
                    className={styles.whiteDropdownWrapper}
                    triggerClassName={styles.brands}
                    value={activeBrandId}
                    list={brandsForHeader}
                    onChange={onSelectBrand}
                    label="Choose brand"
                  />
                </li>
              )}
              {!isAdmin && (
                <>
                  <li>
                    <Popover
                      className={styles.popover}
                      overlayClassName={styles.popoverOverlay}
                      placement="bottomRight"
                      visible={showMoreOptions}
                      onVisibleChange={onShowMoreOptions}
                      content={moreOptions}
                      trigger="click"
                    >
                      <HeaderLink
                        link={{ onClick: () => onShowMoreOptions(true), label: 'Need help?', icon: 'help', id: 'help' }}
                      />
                    </Popover>
                  </li>
                  <WhiteDropDown
                    name="user-dropdown"
                    className={styles.dropdown}
                    triggerClassName={styles.userDropdown}
                    list={settingsLinks}
                    label={user.full_name || 'User'}
                    icon="cog"
                  />
                </>
              )}
              <li className={styles.logoutLi}>
                <HeaderLink link={{ id: 'logout', icon: 'logout', onClick: logout }} />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <TicketModal show={showTicket} onHide={() => setShowTicket(null)} />
      <Modal visible={showEditBrand} onCancel={closeEditBrand} title="Edit brand">
        <BrandForm brand={brand} onClose={closeEditBrand} />
      </Modal>
    </>
  );
};

export default WhiteHeader;
