import { useEffect } from 'react';
import client from '../utils/apiClient';
import axios, { AxiosRequestConfig, CancelToken } from 'axios';
import { clearCampaigns } from '../store/campaigns/actions';
import { logout as signOut } from '../store/auth/actions';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import * as T from '../utils/type';
import {
  AccountLicense,
  FindAccountDocumentPayloadType,
  PaymentAddon,
  PaymentTerm,
  SignInByOptimovePayloadType
} from '../utils/type';

const API = (token: string | null) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  client.defaults.headers = {
    Authorization: token ? `Bearer ${token}` : null
  };

  useEffect(() => {
    const id = client.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const status = error.response?.status;
        if (status === 401) {
          dispatch(clearCampaigns());
          dispatch(signOut());
          localStorage.removeItem('token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('refresh_token_expired_at');
          if (history && location && location.pathname !== '/login')
            history.push('/login', { from: location.pathname });
        }
        return Promise.reject(error);
      }
    );
    return () => {
      client.interceptors.response.eject(id);
    };
  }, []);

  return {
    getListOfLicenses: (id: number) => client.get(`accounts/${id}/payments`),
    getLicense: (accountId: number, licenseId: number) => client.get(`accounts/${accountId}/payments/${licenseId}`),
    createLicense: (id: number, data: Partial<AccountLicense>) => client.post(`accounts/${id}/payments`, data),
    updateLicense: (accountId: number, licenseId: number, data: Partial<AccountLicense>) =>
      client.put(`accounts/${accountId}/payments/${licenseId}`, data),
    deleteLicense: (accountId: number, licenseId: number) =>
      client.delete(`accounts/${accountId}/payments/${licenseId}`),
    createAddon: (accountId: number, licenseId: number, data: Partial<PaymentAddon>) =>
      client.post(`accounts/${accountId}/payments/${licenseId}/addons`, data),
    deleteAddon: (accountId: number, licenseId: number, addonId: number) =>
      client.delete(`accounts/${accountId}/payments/${licenseId}/addons/${addonId}`),
    getAllLicenses: (query: string) => client.get(`payment-licenses?limit=50&${query}`),
    exportLicenses: (query: string, type: string) =>
      client.get(`payment-licenses/export?exportingType=${type}&${query}`, { responseType: 'blob' }),
    getLicensesMonthlyStatistics: (query: string) => client.get(`payment-monthly-statistics?${query}`),
    getStatisticByLicenseType: (query: string) => client.get(`payment-statistics?${query}`),
    generateLicenseInvoice: (accountId: number, data: T.GenerateInvoiceType) =>
      client.post(`accounts/${accountId}/payments/invoice`, data),
    adminUpdateAccountCompanyInfo: (accountId: number, data: T.UpdateAccountCompanyPayloadType) =>
      client.put(`account/${accountId}/update-company-info`, data),
    getCustomerInvoices: (accountId: number, data: T.GetCustomerInvoicesType) =>
      client.get(`accounts/${accountId}/invoices`),
    createPaymentTerm: (accountId: number, licenseId: number, data: Partial<PaymentTerm>) =>
      client.post(`accounts/${accountId}/payments/${licenseId}/terms`, data),
    updatePaymentTerm: (accountId: number, licenseId: number, termId: number, data: Partial<PaymentTerm>) =>
      client.put(`accounts/${accountId}/payments/${licenseId}/terms/${termId}`, data),
    deletePaymentTerm: (accountId: number, licenseId: number, termId: number) =>
      client.delete(`accounts/${accountId}/payments/${licenseId}/terms/${termId}`),
    findAccountDocuments: (accountId: number, params: FindAccountDocumentPayloadType) =>
      client.get(`accounts/${accountId}/documents`, { params }),
    createAccountDocument: (accountId: number, data: FormData, config?: AxiosRequestConfig) =>
      client.post(`accounts/${accountId}/documents`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        ...(config || {})
      }),
    deleteAccountDocument: (accountId: number, documentId: number) =>
      client.delete(`accounts/${accountId}/documents/${documentId}`),
    // accounts/:accountId/payments/:id/invoice
    //ADMIN PAYMENT API

    // AUTH API
    signIn: (data: T.SignInPayloadType) =>
      client.post('/auth/sign-in', data, { headers: { Authorization: token ? `Bearer ${token}` : null } }),
    join: (data: T.CreateAccountPayloadType) => client.post('/account', data),
    signUp: (data: T.PasswordConformationPayloadType) => client.post('/auth/sign-up', data),
    forgotPassword: (data: T.ForgotPasswordPayloadType) => client.post('/auth/forgot-password', data),
    saveNewPassword: (data: T.SaveNewPasswordPayloadType) => client.post('/auth/save-new-password', data),
    updateProfile: (data: T.UpdateAccountProfilePayloadType) => client.put('/auth/update-profile', data),
    changePassword: (data: T.ChangePasswordPayloadType) => client.put('/auth/change-password', data),
    signInByRefreshToken: (data: T.SignInByTokenPayloadType) => client.post('/auth/sign-in-by-refresh-token', data),
    submit2faCode: (data: T.Submit2faCodeType) => client.post('/auth/submit-2fa-code', data),
    resend2faCode: (data: T.Resend2faCodeType) => client.post('/auth/resend-2fa-code', data),
    switchAccount: (id: number) => client.post(`/auth/account/${id}`),

    // OPTIMOVE API
    signInByOptimove: (data: SignInByOptimovePayloadType) =>
      client.post('/optimove/sign-in', data, { withCredentials: true }),

    // ACCOUNT API
    getAccountLimits: () => client.get('/account/used-limits'),
    getAccountUniqueVisitorsCount: () => client.get('/account/unique-visitors-count'),
    getBrandsAndCampaignsCount: () => client.get('/account/amount-of-brands-and-campaigns'),
    updateAccountCompanyInfo: (data: T.UpdateAccountCompanyPayloadType) =>
      client.put('/account/update-company-info', data),
    updateAccount: (data: T.UpdateAccountPayload) => client.put('/account', data),
    getAccountTierSettings: () => client.get('account/tier-settings'),

    getUsers: (page: number, query: string) => client.get(`/users?page=${page}&limit=50&${query}`),
    getUser: (id: number) => client.get(`/users/${id}`),
    updateUser: (id: number, data: T.UserUpdatePayloadType) => client.put(`/users/${id}`, data),
    deleteUser: (id: number) => client.delete(`/users/${id}`),
    getUserTags: () => client.get(`/user-tags`),
    receiveCampaignNotifications: (data: T.ReceiveCampaignNotificationsPayloadType) =>
      client.post(`/users/receive-campaign-notifications`, data),
    removeUserFromAccount: (userId: number, accountId: number) => client.delete(`users/${userId}/account/${accountId}`),

    getInvitations: (page: number, query: string) => client.get(`/invitations?page=${page}&limit=50&${query}`),
    createInvitation: (data: T.CreateInvitationPayloadType) => client.post('/invitations', data),
    resendInvitation: (id: number) => client.post(`/invitations/${id}/resend`),

    // BRANDS API
    createBrand: (data: T.CreateBrandPayloadType) => client.post('/account/brands', data),
    getBrand: (id: number | string) => client.get(`/account/brands/${id}`),
    getBrands: () => client.get('/account/brands-with-campaigns-list'),
    getAdminAccountBrands: (accountId: string) => client.get(`/admin/accounts/${accountId}/brands-with-campaigns-list`),
    updateBrand: (id: number, data: T.UpdateBrandPayloadType) => client.put(`/account/brands/${id}`, data),
    destroyBrand: (id: number) => client.delete(`/account/brands/${id}`),

    // BRANDS API
    getBrandEmails: (id: number, query: string) => client.get(`/brands/${id}/emails?${query}`),
    createBrandEmail: (id: number, data: T.CreateBrandEmailPayloadType) => client.post(`/brands/${id}/emails`, data),
    updateBrandEmail: (id: number, emailId: number, data: T.UpdateBrandEmailPayloadType) =>
      client.put(`/brands/${id}/emails/${emailId}`, data),
    resendBrandEmailConformation: (id: number, emailId: number) =>
      client.post(`/brands/${id}/emails/${emailId}/resend`),
    deleteBrandEmail: (id: number, emailId: number) => client.delete(`/brands/${id}/emails/${emailId}`),
    confirmBrandEmail: (data: T.ConfirmBrandEmailPayloadType) => client.post(`/brand-emails/confirm`, data),
    getCampaignsWithEmail: (id: number, emailId: number) => client.get(`/brands/${id}/emails/${emailId}/campaigns`),

    adminChangeBrandAccount: (id: number, accountId: number) =>
      client.post(`/admin/brands/${id}/change-brand-account/${accountId}`),
    adminGetAccountAndBrandUsedLimits: (id: number, accountId: number) =>
      client.get(`/admin/brands/${id}/used-limits/${accountId}`),

    // GAMES API
    getGamesList: () => client.get('/account/games'),
    getAllGames: () => client.get('/account/games-for-single-plan'),
    getTriviaQuestionsExample: () => client.get(`/account/games/trivia-csv-example`, { responseType: 'blob' }),

    // UPLOAD API
    upload: (data: FormData, config?: AxiosRequestConfig) =>
      client.post('/upload', data, { headers: { 'Content-Type': 'multipart/form-data' }, ...(config || {}) }),
    uploadFont: (data: FormData, config?: AxiosRequestConfig) =>
      client.post('/upload-font', data, { headers: { 'Content-Type': 'multipart/form-data' }, ...(config || {}) }),
    uploadVideo: (data: T.UploadVideoPayloadType, config?: AxiosRequestConfig) =>
      client.post('/upload-video', data, config),
    uploadEmbedScript: (data: FormData, config?: AxiosRequestConfig) =>
      client.post('/upload-embedded-code', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        ...(config || {})
      }),
    resize: (data: T.ResizeImagePayloadType) => client.post('/resize', data),
    getVideoProcessingProgress: (videoId: number) => client.get(`/video-processing-progress/${videoId}`),
    getVideoPreSignUrl: () => client.get('/get-s3-pre-signed-url?type=video'),
    uploadVideoToS3: (url: string, file: File, config?: AxiosRequestConfig) => axios.put(url, file, config),
    checkVideoAvailability: (videoId: number) =>
      axios.get(
        `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}&color=%233261ec&loop=false&width=720&responsive=false&autoplay=false&controls=true`
      ),
    uploadFilesHubSpot: (data: FormData, config?: AxiosRequestConfig) =>
      client.post('account/hubspot-ticket/files', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        ...(config || {})
      }),

    // CAMPAIGNS API
    getCampaigns: (brandId: number, page: number) => client.get(`/account/campaigns?brandId=${brandId}&page=${page}`),
    getCampaignsLimits: (page: number, query: string) =>
      client.get(`/account/active-and-paused-campaigns-analytic?page=${page}&limit=5&${query}`),
    getCampaign: (id: number) => client.get(`/account/campaigns/${id}`),
    searchCampaigns: (query: string) => client.get(`/account/campaigns/autocomplete?${query}`),
    duplicateCampaign: (id: number, data: T.DuplicateCampaignPayloadType) =>
      client.post(`/account/campaigns/${id}/duplicate`, data),
    deleteCampaignConnection: (id: number, connectedId: number) =>
      client.delete(`/account/campaigns/${id}/connected/${connectedId}`),
    getConnectedCampaigns: (id: number) => client.get(`/account/campaigns/${id}/connected`),
    getAvailableForConnectCampaigns: (id: number) => client.get(`/account/campaigns/${id}/available-for-connection`),
    createCampaignConnection: (id: number, connectedId: number) =>
      client.post(`/account/campaigns/${id}/connected/${connectedId}`),
    deleteCampaign: (id: number) => client.delete(`/account/campaigns/${id}`),
    createCampaign: (data: T.CreateCampaignPayloadType) => client.post(`/account/campaigns/`, data),
    updateCampaignFromScreenSetupWizard: (id: number, data: any) =>
      client.post(`/account/campaigns/${id}/wizard-setup`, data),
    updateCampaign: (id: number, data: any, cancelToken?: CancelToken) =>
      client.put(`/account/campaigns/${id}`, data, { cancelToken }),
    translateCampaign: (id: number, data: T.TranslateCampaignPayloadType) =>
      client.post(`/account/campaigns/${id}/translate`, data),
    getRelatedCampaigns: (id: number) => client.get(`/account/campaigns/${id}/list-to-connect-to-translations`),
    publishCampaignChanges: (id: number) => client.post(`/campaigns/${id}/update-cache`),
    getNewsPosts: (page: number) => client.get(`/account/news?page=${page}&limit=3`),
    updateNewsPostClicks: (id: number) => client.post(`/account/news/${id}/clicks`),
    getCampaignInputList: (id: number) => client.get(`/campaigns/${id}/input-list`),
    removeFileBackground: (data: FormData) =>
      client.post('/account/add-file-to-remove-bg-service', data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }),
    republishDuplicateCampaign: (id: number, data: T.RepublishCampaignAsNewPayloadType) =>
      client.post(`account/campaigns/${id}/publish-duplicate`, data),
    getCampaignUploadedContent: (id: number, page: number, query: string) =>
      client.get(`/account/campaigns/${id}/uploaded-contents?page=${page}&limit=50&${query}`),
    updateCampaignUploadedContent: (id: number, contentId: string, data: any) =>
      client.put(`/account/campaigns/${id}/uploaded-contents/${contentId}`, data),
    createCampaignUploadedContent: (id: number, data: any) =>
      client.post(`/account/campaigns/${id}/uploaded-contents`, data),
    deleteCampaignUploadedContent: (id: number, contentId: string) =>
      client.delete(`/account/campaigns/${id}/uploaded-contents/${contentId}`),
    getCampaignLandingPageAllowedValues: (id: number, page: number, query: string) =>
      client.get(`/campaigns/${id}/allowed-values?page=${page}&limit=50&${query}`),
    createCampaignLandingPageAllowedValues: (id: number, data: T.CreateAllowedValuesPayloadType) =>
      client.post(`/campaigns/${id}/allowed-values`, data),
    deleteCampaignLandingPageAllowedValues: (id: number, data: Record<string, any>) =>
      client.delete(`/campaigns/${id}/allowed-values`, { data }),
    getCampaignAllowedDomains: (id: number) => client.get(`/account/campaigns/${id}/email-domains`),
    createCampaignAllowedDomains: (id: number, data: T.CreateAllowedEmailDomainsType) =>
      client.post(`/account/campaigns/${id}/email-domains`, data),
    updateCampaignAllowedDomains: (id: number, data: T.CreateAllowedEmailDomainsType) =>
      client.put(`/account/campaigns/${id}/email-domains`, data),
    deleteCampaignAllowedDomains: (id: number) => client.delete(`/account/campaigns/${id}/email-domains`),
    getSharedLeaderboardSettings: (id: number) => client.get(`/account/campaigns/${id}/shared-leaderboard-setting`),
    adminGetSharedLeaderboardSettings: (id: number) => client.get(`/admin/campaigns/${id}/shared-leaderboard-setting`),
    updateSharedLeaderboardSettings: (id: number, data: T.UpdateShardLeaderboardListType) =>
      client.post(`/account/campaigns/${id}/shared-leaderboard-setting`, data),
    adminUpdateSharedLeaderboardSettings: (id: number, data: T.UpdateShardLeaderboardListType) =>
      client.post(`/admin/campaigns/${id}/shared-leaderboard-setting`, data),
    deleteSharedLeaderboardSettings: (id: number) =>
      client.delete(`/account/campaigns/${id}/shared-leaderboard-setting`),
    adminDeleteSharedLeaderboardSettings: (id: number) =>
      client.delete(`/admin/campaigns/${id}/shared-leaderboard-setting`),
    getListForLeaderboardConnection: (id: number) => client.get(`/campaigns/${id}/list-for-leaderboard`),
    lockDraftCampaign: (id: number) => client.post(`/campaigns/${id}/locked`),
    favoriteCampaign: (id: number, payload: T.SetCampaignFavoritePayloadType) =>
      client.post(`/campaigns/${id}/favorite`, payload),
    restoreCampaign: (id: number) => client.post(`/campaigns/${id}/restore`),

    // CAMPAIGN STATUSES
    getCampaignStatuses: () => client.get('/campaigns/statuses'),

    // SCREENS API
    getScreenTypes: () => client.get('/screen-types'),
    getLandingPageElements: (brandId: any) => client.get(`/landing-page-elements?brandId=${brandId}`),
    getAdminLandingPageElements: () => client.get('/landing-page-elements'),

    // PRIZES API
    getCampaignPrizes: (id: string | number) => client.get(`/account/campaigns/${id}/prizes`),
    createCampaignPrize: (id: any, data: T.CreatePrizePayloadType) =>
      client.post(`/account/campaigns/${id}/prizes`, data),
    updateCampaignPrize: (id: any, data: T.CreatePrizePayloadType, prizeId: string) =>
      client.put(`/account/campaigns/${id}/prizes/${prizeId}`, data),
    getCampaignPrizeCustomCodes: (prizeId: string, page: number, query: string) =>
      client.get(`/account/prizes/${prizeId}/prize-codes?page=${page}&limit=1000&${query}`),
    adminGetCampaignPrizeCustomCodes: (prizeId: string, page: number, query: string) =>
      client.get(`/admin/prizes/${prizeId}/prize-codes?page=${page}&limit=1000&${query}`),
    removePrize: (id: any, prizeId: string) => client.delete(`/account/campaigns/${id}/prizes/${prizeId}`),
    rafflePrize: (id: any, prizeId: string, data: T.RafflePrizePayload) =>
      client.post(`/campaigns/${id}/raffle/${prizeId}`, data),
    rafflePrizeDetails: (id: any, historyId: string) =>
      client.get(`/account/campaigns/${id}/raffling-histories/${historyId}`),
    getCampaignWinners: (id: any, page: number, search?: string) =>
      client.get(`/account/campaigns/${id}/assigned-prizes?page=${page}&limit=50&${search ? search : ''}`),
    exportWinners: (id: any, type: string) =>
      client.get(`/account/campaigns/${id}/assigned-prizes/export?exportingType=${type}`, { responseType: 'blob' }),
    removeWinner: (id: any, winnerId: string) => client.delete(`/account/campaigns/${id}/assigned-prizes/${winnerId}`),
    removeWinners: (id: any, data: T.DeleteAssignedPrizesPayloadType) =>
      client.delete(`/account/campaigns/${id}/assigned-prizes/bulk-delete`, { data }),
    sendEmailToWinners: (id: any, assignedPrizesIds: string[]) =>
      client.post(`/campaigns/${id}/assigned-prizes/resend-winner-emails`, { assignedPrizesIds }),
    getEmailRecipients: (id: number, query: string) => client.get(`/campaigns/${id}/email-recipients?${query}`),
    getPlayersForAssignment: (campaignId: number, query: string) =>
      client.get(`/campaigns/${campaignId}/players-for-assignment?${query}`),
    assignPrizeToPlayers: (campaignId: number, id: string, data: T.AssignPrizePayloadType) =>
      client.post(`/campaigns/${campaignId}/prizes/${id}/assign`, data),

    // EMAILS API
    getEmails: (id: number) => client.get(`/campaigns/${id}/email-templates`),
    createEmailTemplate: (id: any, data: Partial<T.EmailTemplate>) =>
      client.post(`/campaigns/${id}/email-templates`, data),
    updateEmail: (campaignId: number, id: string, data: Partial<T.EmailTemplate>) =>
      client.put(`/campaigns/${campaignId}/email-templates/${id}`, data),
    sendTestEmail: (campaignId: any, templateId: string, data: T.SendTestEmailPayloadType) =>
      client.post(`/campaigns/${campaignId}/email-templates/${templateId}/send-test-email`, data),
    deleteEmail: (campaignId: any, templateId: string) =>
      client.delete(`/campaigns/${campaignId}/email-templates/${templateId}`),
    getEmailDomains: () => client.get('/custom-email-domains'),
    createEmailDomain: (data: T.CreateCustomDomainPayloadType) => client.post('/custom-email-domains', data),
    verifyEmailDomain: (domainId: string) => client.post(`/custom-email-domains/${domainId}/verify`),
    deleteEmailDomain: (domainId: string) => client.delete(`/custom-email-domains/${domainId}`),

    // FONTS API
    getBrandFonts: (brandId: number) => client.get(`/account/brands/${brandId}/fonts`),
    removeBrandFont: (brandId: number, fontId: number) => client.delete(`/account/brands/${brandId}/fonts/${fontId}`),
    createBrandFont: (brandId: number, data: T.CreateBrandFontPayloadType) =>
      client.post(`/account/brands/${brandId}/fonts`, data),

    // ANALYTICS API
    getCampaignsWithAnalytics: (brandId: number, page: number, limit: number, query: string) =>
      client.get(`/account/campaigns-with-analytic?brandId=${brandId}&page=${page}&limit=${limit}&${query}`),
    getOneAnalytics: (id: string, query: string) => client.get(`/campaigns-with-analytic/${id}?${query}`),
    getCombinedDailyStatistics: (id: number, query: string) =>
      client.get(`/campaigns/${id}/combined-single-daily-statistic?${query}`),
    getDailyStatistics: (id: number, query: string) => client.get(`/campaigns/${id}/daily-statistics?${query}`),
    exportDailyStatistics: (id: number, type: T.ExportFileType) =>
      client.get(`/account/campaigns/${id}/daily-statistics/export?exportingType=${type}`, { responseType: 'blob' }),
    getOneDailyStatistics: (campaignId: number, id: string) =>
      client.get(`/account/campaigns/${campaignId}/daily-statistics/${id}`),
    adminGetOneDailyStatistics: (campaignId: number, id: string) =>
      client.get(`/admin/campaigns/${campaignId}/daily-statistics/${id}`),
    getTopPlayers: (id: number, query: string) => client.get(`/campaigns/${id}/top-players?${query}`),
    exportPlayers: (id: number, data: T.ExportingPayloadType) =>
      client.post(`/account/campaigns/${id}/players-export`, data),
    getPlayersActivity: (id: number, query: string) => client.get(`/campaigns/${id}/players-activity?${query}`),
    getGameplays: (id: number, page: number, search: string, sort?: string) =>
      client.get(`/account/campaigns/${id}/gameplay?page=${page}&limit=10&${search}&${sort}`),
    exportGameplays: (id: number, data: T.ExportingPayloadType) =>
      client.post(`/campaigns/${id}/gameplay-export`, data),
    unmarkCheatedGameplays: (data: T.UnmarkCheatedGameplaysPayloadType) =>
      client.post('/gameplay/unmark-cheated', data),
    getButtonsAndTraffics: (id: number, query: string) => client.get(`/campaigns/${id}/buttons-and-traffics?${query}`),
    deleteGameplay: (campaignId: number, gameplayId: string) =>
      client.delete(`/account/campaigns/${campaignId}/gameplay/${gameplayId}`),
    anonymizePlayers: (id: number) => client.post(`/account/campaigns/${id}/anonymize`),
    resetCampaignStatistics: (id: number) => client.post(`/campaigns/${id}/reset-campaign`),
    campaignLeaderboardData: (id: number, query: string) => client.get(`/campaigns/${id}/leaderboard?${query}`),
    campaignPollStatisticsData: (id: number) => client.get(`/account/campaigns/${id}/poll-statistic`),
    adminCampaignPollStatisticsData: (id: number) => client.get(`/admin/campaigns/${id}/poll-statistic`),
    getEmailDailyStatistics: (id: number, query: string) =>
      client.get(`/campaigns/${id}/email-daily-statistics?${query}`),
    getEmailStatistics: (id: number, query: string) => client.get(`/campaigns/${id}/email-statistic?${query}`),
    exportEmailDailyStatistics: (id: number, type: string) =>
      client.get(`campaigns/${id}/email-daily-statistics/export?exportingType=${type}`, { responseType: 'blob' }),
    getTriviaGameAnalytics: (id: number) => client.get(`/campaigns/${id}/trivia-statistic`),
    getTriviaGameQuestionsAnalytics: (id: number, query: string) =>
      client.get(`/campaigns/${id}/trivia-question-statistics?${query}`),
    getPlayersQuestionsAnalytics: (id: number, query: string) =>
      client.get(`/campaigns/${id}/players-with-questions?${query}`),
    postPredictionGameScoreGeneration: (id: number, data: T.GeneratePredictionScorePayloadType) =>
      client.post(`/campaigns/${id}/generate-prediction-score`, data),

    // FOLDERS
    changeCampaignsFolder: (brandId: number, folderId: number, data: Record<string, any>) =>
      client.post(`/brands/${brandId}/folders/${folderId}/add-campaigns`, data),
    getFolders: (brandId: number, query: string) => client.get(`/brands/${brandId}/folders?${query}`),
    createFolder: (brandId: number, data: Record<string, any>) => client.post(`/brands/${brandId}/folders`, data),
    updateFolder: (id: number, brandId: number, data: Record<string, any>) =>
      client.put(`/brands/${brandId}/folders/${id}`, data),
    deleteFolder: (id: number, brandId: number) => client.delete(`/brands/${brandId}/folders/${id}`),

    // ADMIN API
    getAdminGames: () => client.get('/admin/games'),
    getAdminGame: (id: string) => client.get(`/admin/games/${id}`),
    updateAdminGame: (id: string, data: FormData) =>
      client.put(`/admin/games/${id}`, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
    getAdminGameTags: () => client.get('/game-tags'),
    getAdminGameTag: (id: number) => client.get(`/game-tags/${id}`),
    updateAdminGameTag: (id: number, data: T.UpdateGameTagPayloadType) => client.put(`/game-tags/${id}`, data),
    deleteAdminGameTag: (id: number) => client.delete(`/game-tags/${id}`),
    createAdminGameTag: (data: T.CreateGameTagPayloadType) => client.post('/game-tags', data),
    getAdminCampaigns: (page: number, query: string) => client.get(`/admin/campaigns?page=${page}&limit=50&${query}`),
    getAdminCampaignsAutocomplete: (search: string) => client.get(`/admin/campaigns/autocomplete?search=${search}`),
    getAdminBrandsAutocomplete: (query: string) => client.get(`/admin/brands/autocomplete?${query}`),
    getAdminAccountsAutocomplete: (query: string) => client.get(`/admin/accounts/autocomplete?${query}`),
    getAdminPlayers: (page: number, query: string) => client.get(`/admin/players?page=${page}&limit=50&${query}`),
    deleteAdminPlayer: (id: string) => client.put(`/admin/players/${id}/clear-data`),
    getAdminBrands: (page: number, query: string) => client.get(`/admin/brands?page=${page}&limit=50&${query}`),
    getAdminBrand: (id: string) => client.get(`/admin/brands/${id}`),
    updateAdminBrand: (id: string, data: T.UpdateBrandPayloadType) => client.put(`/admin/brands/${id}`, data),
    deleteAdminBrand: (id: string) => client.delete(`/admin/brands/${id}`),
    getAdminAccounts: (page: number, query: string) => client.get(`/admin/accounts?page=${page}&limit=50&${query}`),
    createAdminAccount: (data: T.CreateAccountByAdminPayloadType) => client.post('/admin/accounts', data),
    deleteAdminAccount: (id: number) => client.delete(`admin/accounts/${id}`),

    updateAdminAccount: (id: number, data: T.UpdateAccountByAdminPayloadType) =>
      client.put(`/admin/accounts/${id}`, data),
    updateAccountLimits: (data: T.AccountLimitsUpdatePayload) => client.put('/admin/accounts/change-limits', data),
    getAdminAccount: (id: string | number) => client.get(`/admin/accounts/${id}`),
    createAdminBrand: (data: T.CreateBrandByAdminPayloadType) => client.post('/admin/brands', data),
    updateAdminAccountBrand: (id: number, data: T.CreateBrandByAdminPayloadType) =>
      client.put(`/admin/brands/${id}`, data),
    resendAccountInvitation: (id: number) => client.post(`/admin/accounts/${id}/resend-invitation`),
    sendNewPassword: (id: number) => client.post(`/admin/accounts/${id}/send-new-password`),
    getAdminNewsPosts: (page: number, query: string) => client.get(`/admin/news?page=${page}&limit=50&${query}`),
    getAdminNewsPost: (id: string) => client.get(`/admin/news/${id}`),
    updateAdminNewsPost: (id: number | string, data: FormData) =>
      client.put(`/admin/news/${id}`, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
    createAdminNewsPost: (data: FormData) =>
      client.post('/admin/news', data, { headers: { 'Content-Type': 'multipart/form-data' } }),
    deleteAdminNewsPost: (id: number) => client.delete(`/admin/news/${id}`),

    // ADMIN CUSTOMER SUCCESS
    getTopPerformingCampaigns: (page: number, query: string) =>
      client.get(`admin/campaigns/top-performing?page=${page}&limit=50&${query}`),
    getCampaignsByAverageGameplay: (page: number, query: string) =>
      client.get(`admin/campaigns/daily-statistics-average?page=${page}&limit=50&${query}`),
    getRecentlyPublishedCampaigns: (page: number, query: string) =>
      client.get(`admin/campaigns/live?page=${page}&limit=50&${query}`),
    getEndedCampaigns: (page: number, query: string) =>
      client.get(`admin/campaigns/ended?page=${page}&limit=50&${query}`),
    getAccountsCountries: () => client.get('/admin/accounts/counties'),
    getInactiveAccounts: (page: number, query: string) =>
      client.get(`/admin/accounts/inactive?page=${page}&limit=50&${query}`),
    getExpiringAccounts: (page: number, query: string) =>
      client.get(`/admin/accounts/expiring?page=${page}&limit=50&${query}`),
    getAccountsThatHaveNotPublished: (page: number, query: string) =>
      client.get(`/admin/accounts/not-published?page=${page}&limit=50&${query}`),
    getJoinedAccounts: (query: string) => client.get(`/admin/accounts/joined?${query}`),
    getAccountStatistics: (accountId: number) => client.get(`/admin/accounts/${accountId}/statistics`),

    // ADMIN CAMPAIGN-TEMPLATES API
    getAdminCampaignTemplates: (page: number, query: string) =>
      client.get(`/admin/campaign-templates?page=${page}&limit=50&${query}`),
    getOneAdminCampaignTemplate: (id: number) => client.get(`/admin/campaign-templates/${id}`),
    getDefaultAdminCampaignTemplate: () => client.get('/admin/campaign-templates-default'),
    createAdminCampaignTemplate: (data: FormData) =>
      client.post('admin/campaign-templates', data, { headers: { 'Content-Type': 'multipart/form-data' } }),
    updateAdminCampaignTemplate: (id: number, data: FormData) =>
      client.put(`/admin/campaign-templates/${id}`, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
    deleteAdminCampaignTemplate: (id: number) => client.delete(`/admin/campaign-templates/${id}`),

    // BRAND CAMPAIGN-TEMPLATES API
    getBrandCampaignTemplates: (id: number) => client.get(`/account/brands/${id}/campaign-templates`),
    createBrandCampaignTemplate: (id: number, data: any) =>
      client.post(`/account/brands/${id}/campaign-templates`, data),
    updateBrandCampaignTemplate: (brandId: number, templateId: number, data: any) =>
      client.put(`/account/brands/${brandId}/campaign-templates/${templateId}`, data),
    deleteBrandCampaignTemplate: (brandId: number, templateId: number) =>
      client.delete(`/account/brands/${brandId}/campaign-templates/${templateId}`),

    // BRAND SCREEN-TEMPLATES API
    getBrandScreenTemplates: (id: number) => client.get(`brands/${id}/screen-templates`),
    createBrandScreenTemplate: (id: number, data: any) => client.post(`brands/${id}/screen-templates`, data),
    deleteBrandScreenTemplate: (brandId: number, templateId: number) =>
      client.delete(`brands/${brandId}/screen-templates/${templateId}`),

    // ADMIN CAMPAIGN API
    getAdminCampaign: (id: number) => client.get(`/admin/campaigns/${id}`),
    adminDuplicateCampaign: (id: number, data: T.DuplicateCampaignByAdmin) =>
      client.post(`/admin/campaigns/${id}/duplicate`, data),
    deleteAdminCampaign: (id: number) => client.delete(`/admin/campaigns/${id}`),
    updateAdminCampaign: (id: number, data: any, cancelToken?: CancelToken) =>
      client.put(`/admin/campaigns/${id}`, data, { cancelToken }),
    getAdminRelatedCampaigns: (id: number) => client.get(`/admin/campaigns/${id}/list-to-connect-to-translations`),
    adminPublishDuplicateCampaign: (id: number, data: T.RepublishCampaignAsNewPayloadType) =>
      client.post(`admin/campaigns/${id}/publish-duplicate`, data),
    adminChangeCampaignBrand: (id: number, brandId: number) =>
      client.post(`/admin/campaigns/${id}/change-campaign-brand/${brandId}`),

    // ADMIN ANALYTICS API
    exportAdminPlayers: (id: any, data: T.ExportingPayloadType) =>
      client.post(`/admin/campaigns/${id}/players-export`, data),
    getAdminGameplays: (id: number, page: number, search: string, sort?: string) =>
      client.get(`/admin/campaigns/${id}/gameplay?page=${page}&limit=10&${search}&${sort}`),
    deleteAdminGameplay: (campaignId: number, gameplayId: string) =>
      client.delete(`/admin/campaigns/${campaignId}/gameplay/${gameplayId}`),
    anonymizeAdminPlayers: (id: number) => client.post(`/admin/campaigns/${id}/anonymize`),
    exportAdminDailyStatistics: (id: number, type: T.ExportFileType) =>
      client.get(`/admin/campaigns/${id}/daily-statistics/export?exportingType=${type}`, { responseType: 'blob' }),

    // ADMIN BRAND FONTS API
    getAdminBrandFonts: (brandId: number) => client.get(`/admin/brands/${brandId}/fonts`),

    // ADMIN PRIZES API
    getAdminCampaignPrizes: (id: string | number) => client.get(`/admin/campaigns/${id}/prizes`),
    createAdminCampaignPrize: (id: any, data: T.CreatePrizePayloadType) =>
      client.post(`/admin/campaigns/${id}/prizes`, data),
    updateAdminCampaignPrize: (id: any, data: T.CreatePrizePayloadType, prizeId: string) =>
      client.put(`/admin/campaigns/${id}/prizes/${prizeId}`, data),
    removeAdminPrize: (id: any, prizeId: string) => client.delete(`/admin/campaigns/${id}/prizes/${prizeId}`),
    raffleAdminPrizeDetails: (id: any, historyId: string) =>
      client.get(`/admin/campaigns/${id}/raffling-histories/${historyId}`),
    getAdminCampaignWinners: (id: any, page: number, search?: string) =>
      client.get(`/admin/campaigns/${id}/assigned-prizes?page=${page}&limit=10&${search ? search : ''}`),
    exportAdminWinners: (id: any, type: string) =>
      client.get(`/admin/campaigns/${id}/assigned-prizes/export?exportingType=${type}`, { responseType: 'blob' }),
    removeAdminWinner: (id: any, winnerId: string) =>
      client.delete(`/admin/campaigns/${id}/assigned-prizes/${winnerId}`),
    removeAdminWinners: (id: any, data: T.DeleteAssignedPrizesPayloadType) =>
      client.delete(`/admin/campaigns/${id}/assigned-prizes/bulk-delete`, { data }),

    // ADMIN GAMES API
    getAdminGamesList: () => client.get('/admin/games'),

    // CAMPAIGN S3
    getS3Campaign: (campaignId: string) =>
      axios.get(`${process.env.REACT_APP_CAMPAIGN_S3_URL}/${campaignId}`, {
        headers: {
          'Cache-Control': 'no-cache'
        }
      }),

    // INTEGRATION API
    getAccountIntegration: () => client.get('/account/integration'),
    createAccountIntegration: (data: T.CreateApiIntegrationPayloadType) => client.post('/account/integration', data),
    updateAccountIntegration: (data: T.CreateApiIntegrationPayloadType) => client.put('/account/integration', data),
    regenerateIntegrationKey: () => client.post('/account/integration/regenerate-api-key'),
    getIntegrationSegments: () => client.get('/account/integration/segments'),
    createIntegrationSegments: (data: T.IntegrationSegmentPayloadType) =>
      client.post('/account/integration/segments', data),
    updateIntegrationSegments: (segmentId: string, data: T.IntegrationSegmentPayloadType) =>
      client.put(`/account/integration/segments/${segmentId}`, data),
    deleteIntegrationSegments: (segmentId: string) => client.delete(`/account/integration/segments/${segmentId}`),
    getIntegrationWebhookEvents: (page: number) =>
      client.get(`/account/integration/webhook-events?page=${page}&limit=10`),
    resendIntegrationWebhookEvent: (eventId: string) =>
      client.post(`/account/integration/webhook-events/${eventId}/resend`),
    getIntegrationWebhookEvent: (eventId: string) => client.get(`/account/integration/webhook-events/${eventId}`),

    getCustomDomains: () => client.get('/account/custom-domains'),
    createCustomDomain: (data: T.CreateCustomDomainPayloadType) => client.post('/account/custom-domains', data),
    updateCustomDomain: (data: T.UpdateCustomDomainPayloadType) => client.patch(`/account/custom-domains`, data),
    deleteCustomDomain: () => client.delete('/account/custom-domains'),
    getBrandsTitles: () => client.get('/account/brand-titles'),
    getBrandSlugs: (brandId: string) => client.get(`/brands/${brandId}/campaign-slugs`),

    // HUBSPOT INTEGRATION API
    setBrandHubSpotConnection: (brandId: number, data: T.CreateHubspotConnectionPayloadType) =>
      client.post(`account/brands/${brandId}/hubspot`, data),
    confirmBrandHubSpotConnection: (data: T.UpdateHubspotConnectionPayloadType) =>
      client.put(`account/hubspot/confirm-connection`, data),
    deleteBrandHubSpotConnection: (brandId: number, id: number) =>
      client.delete(`account/brands/${brandId}/hubspot/${id}`),
    getAccountBrandsHubSpotConnections: () => client.get('account/brands-hubspot-connections'),
    adminGetAccountBrandsHubSpotConnections: (accountId: number) =>
      client.get(`account/brands-hubspot-connections/${accountId}`),
    testHubSpotSubmitContact: (id: number, brandId: number, data: T.TestHubspotContactCreationPayloadType) =>
      client.post(`account/brands/${brandId}/hubspot/${id}`, data),
    getBrandHubSpotClientsFields: (id: number, brandId: number) =>
      client.get(`account/brands/${brandId}/hubspot/${id}`),
    adminGetBrandHubSpotClientsFields: (id: number) => client.get(`admin/brands/hubspot/${id}`),

    // MAILCHIMP INTEGRATION API
    createBrandMailChimpConnection: (brandId: number, data: T.CreateMailchimpConnectionPayloadType) =>
      client.post(`account/brands/${brandId}/mailchimp`, data),
    confirmBrandMailChimpConnection: (id: number, data: T.UpdateMailchimpConnectionPayloadType) =>
      client.put(`account/mailchimp/${id}`, data),
    deleteBrandMailChimpConnection: (brandId: number, id: number) =>
      client.delete(`account/brands/${brandId}/mailchimp/${id}`),
    getAccountBrandsMailChimpConnections: () => client.get('account/brands-mailchimp-connections'),
    adminGetAccountBrandsMailChimpConnections: (accountId: number) =>
      client.get(`account/brands-mailchimp-connections/${accountId}`),
    testMailChimpSubmitContact: (id: number, brandId: number, data: T.TestMailchimpContactCreationPayloadType) =>
      client.post(`account/brands/${brandId}/mailchimp/${id}`, data),
    getBrandMailChimpAudiences: (id: number, brandId: number) =>
      client.get(`account/brands/${brandId}/mailchimp/${id}`),
    adminGetBrandMailChimpAudiences: (id: number) => client.get(`admin/brands/mailchimp/${id}`),
    getBrandMailChimpAudiencesFields: (id: number, query: string, brandId: number) =>
      client.get(`account/brands/${brandId}/mailchimp/${id}/fields?${query}`),
    adminGetBrandMailChimpAudiencesFields: (id: number, query: string) =>
      client.get(`admin/brands/mailchimp/${id}/fields?${query}`),

    // SALESFORCE INTEGRATION API
    getSalesforceConnections: () => client.get('brands-salesforce-connections'),
    createSalesforceConnection: (brandId: number, data: T.CreateHubspotConnectionPayloadType) =>
      client.post(`accounts/brands/${brandId}/salesforce`, data),
    deleteSalesforceConnection: (brandId: number, id: number) =>
      client.delete(`account/brands/${brandId}/salesforce/${id}`),
    testSalesforceConnection: (brandId: number, id: number, data: T.TestSalesForceConnectionPayloadType) =>
      client.post(`account/brands/${brandId}/salesforce/${id}`, data),
    getSalesforceFields: (brandId: number, id: number) => client.get(`brands/${brandId}/salesforce/${id}`),

    // HUBSPOT ADACT PRIVATE APP API
    createHubSpotTicket: (data: T.HubspotTicketPayloadType) => client.post('account/hubspot-ticket', data),

    // SYSTEM NOTIFICATIONS API
    getSystemNotificationsList: (page: number, limit: number, query: string) =>
      client.get(`/system-notifications?offset=${(page - 1) * limit}&limit=${limit}&${query}`),
    getOneSystemNotification: (id: number) => client.get(`/system-notifications/${id}`),
    createSystemNotification: (data: T.SystemNotificationPayloadType) => client.post(`/system-notifications`, data),
    updateSystemNotification: (id: number, data: T.SystemNotificationPayloadType) =>
      client.put(`/system-notifications/${id}`, data),
    deleteSystemNotification: (id: number) => client.delete(`/system-notifications/${id}`),
    userCloseSystemNotification: (id: number) => client.post(`/system-notifications/${id}/close`),

    // MEDIA FILES
    getMediaFilesList: (page: number, limit: number, query: string) =>
      client.get(`/media-files?page=${page}&limit=${limit}&${query}`),
    createMediaFile: (data: FormData, config?: AxiosRequestConfig) =>
      client.post(`/media-files`, data, { headers: { 'Content-Type': 'multipart/form-data' }, ...(config || {}) }),
    updateMediaFiles: (data: T.UpdateMediaFilePayloadType) => client.post(`/media-files/update-categories`, data),
    deleteMediaFile: (data: T.DeleteMediaFilePayloadType) => client.delete(`/media-files/bulk-delete`, { data }),

    //TOKENS
    unlockCampaignByTokens: (id: number, payload: T.CampaignUnlockByTokenPayload) =>
      client.post(`campaigns/${id}/unlock-by-tokens`, payload),

    adminGetTokensList: () => client.get(`token-campaign-unlocking`),
    adminCreateToken: (payload: T.CreateCampaignUnlockByTokenPayloadType) =>
      client.post(`token-campaign-unlocking`, payload),
    adminUpdateToken: (id: number, payload: T.CreateCampaignUnlockByTokenPayloadType) =>
      client.put(`token-campaign-unlocking/${id}`, payload),
    adminDeleteToken: (id: number) => client.delete(`token-campaign-unlocking/${id}`),
    getTokenUsageHistory: (payload: T.PaginationPayload) => client.get(`token-usage-histories`, { data: payload }),

    tokenPurchaseList: () => client.get(`token-purchases`),
    adminCreateTokenPurchase: (payload: FormData) =>
      client.post(`token-purchases`, payload, { headers: { 'Content-Type': 'multipart/form-data' } }),
    adminUpdateTokenPurchase: (id: number, payload: FormData) =>
      client.put(`token-purchases/${id}`, payload, { headers: { 'Content-Type': 'multipart/form-data' } }),
    adminDeleteTokenPurchase: (id: number) => client.delete(`token-purchases/${id}`),
    getTokenPurchaseHistory: (payload: T.PaginationPayload) =>
      client.get(`token-purchase-histories`, { data: payload }),

    //PAYMENTS
    generatePortalSessionUrl: (payload: T.PortalSessionPayload) =>
      client.post('payments/generate-portal-session-url', payload),
    buyTokensUrl: (payload: T.BuyTokensType) => client.post('payments/buy-tokens', payload),
    generateSubscriptionCheckoutUrl: (payload: T.CheckoutSessionPayload) =>
      client.post('payments/subscription-checkout-session', payload),
    setInvoiceCustomFields: (payload: T.InvoiceCustomFieldsPayload) =>
      client.post('payments/invoice-custom-fields', payload),
    cancelSubscription: (payload: T.CancelSubscriptionPayload) =>
      client.delete('payments/cancel-subscription', { data: payload }),

    //SUBSCRIPTION
    createSubscription: (payload: T.CreateSubscriptionPayload) =>
      client.post('account/payments/create-subscription', payload),
    updateSubscription: (payload: T.UpdateSubscriptionPayload) => client.post('payments/update-subscription', payload),
    getPlans: () => client.get('/tier-plans'),
    getPriceOfNextSubscription: (payload: T.UpdateSubscriptionPayload) =>
      client.post('payments/price-of-next-subscription', payload),
    getAccountPayments: () => client.get('account/payments'),

    //COURSES
    createCourse: (payload: T.CreateCoursePayload) => client.post('courses', payload),
    updateCourse: (id: number, payload: T.UpdateCoursePayload) => client.put(`courses/${id}`, payload),
    getCourses: () => client.get('courses'),
    deleteCourse: (id: number) => client.delete(`courses/${id}`),

    //PURCHASE NOTIFICATIONS
    getPurchaseNotifications: () => client.get('purchase-notification'),
    deletePurchaseNotification: (id: string) => client.delete(`purchase-notification/${id}`)
  };
};

export default API;
