import { ImmutableObject } from 'seamless-immutable';
import { TierPlan } from '../../utils/type/tierPlans/tierPlan.type';
import { StripeInvoiceType } from '../../utils/type';

export enum TierPlansStoreEnum {
  TIER_PLANS = 'tier_plans',
  INVOICES = 'invoices'
}

export type TierPlansState = ImmutableObject<{
  [TierPlansStoreEnum.TIER_PLANS]: TierPlan[];
  [TierPlansStoreEnum.INVOICES]: StripeInvoiceType[];
}>;
