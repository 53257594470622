import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import styles from './whiteColorPicker.module.css';
import useBrand from '../../../domain/useBrand';
import Popover from '../../popover';
import Typo from '../../typo';
import cx from 'classnames';
import useCampaign from '../../../domain/useCampaign';
import useCurrentUser from '../../../utils/hooks/useCurrentUser';

type Props = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  currentValue?: string;
};

const BrandColorPicker: FunctionComponent<Props> = ({ onChange, disabled, currentValue }) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const { visualSettings } = useBrand();
  const { campaign } = useCampaign();
  const { isAdmin } = useCurrentUser();

  const brandColors = isAdmin ? JSON.parse(campaign.brand.visual_settings)?.colors : visualSettings.colors;

  if (!brandColors?.length) {
    return null;
  }

  const form = (
    <div className={styles.popoverWrapper}>
      <Typo type="p" className={styles.popoverTitle}>
        Assign color
      </Typo>
      {brandColors.map((color: string) => (
        <button
          className={cx(styles.popoverColor, { [styles.active]: currentValue === color })}
          onClick={() => onChange({ target: { value: color } } as any)}
        >
          <div
            className={styles.colorPin}
            style={{ background: color, color: color === '#000000' ? '#ffffff' : '#000000' }}
          />
          <Typo type="p">{color}</Typo>
        </button>
      ))}
    </div>
  );

  return (
    <Popover
      placement="bottomLeft"
      visible={showForm && !disabled}
      onVisibleChange={setShowForm}
      content={form}
      trigger="click"
    >
      <div className={styles.brandColorWrapper}>
        <div className={cx(styles.pin, styles.pin1)} style={{ background: brandColors[0] }} />
        <div className={cx(styles.pin, styles.pin2)} style={{ background: brandColors[1] }} />
        <div className={cx(styles.pin, styles.pin3)} style={{ background: brandColors[2] }} />
        <div className={cx(styles.pin, styles.pin4)} style={{ background: '#ffffff' }} />
      </div>
    </Popover>
  );
};

export default BrandColorPicker;
