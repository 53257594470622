import React, { FunctionComponent, ReactElement } from 'react';
import { Popover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';

type Props = PopoverProps & {
  children: ReactElement;
  className?: string;
  showPopover?: boolean;
};

const HoverPopover: FunctionComponent<Props> = ({ children, content, showPopover, ...other }) => {
  if (typeof showPopover === 'boolean' && !showPopover) {
    return children;
  }

  return (
    <Popover content={content} {...other}>
      <div>{children}</div>
    </Popover>
  );
};

export default HoverPopover;
