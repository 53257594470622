import React, { FunctionComponent } from 'react';
import styles from './mediaFiles.module.css';
import { MediaFile } from '../../../../../utils/type';
import cx from 'classnames';
import Icon from '../../../../icon';
import Spinner from '../../../../spinner';

export type MediaFileEntry = MediaFile | Pick<MediaFile, 'id' | 'file_key' | 'file_name' | 'brandId' | 'categories'>;

type Props = {
  selectMediaFile: () => void;
  selected: boolean;
  item: MediaFileEntry;
  deleting?: boolean;
};

const MediaFileImage: FunctionComponent<Props> = (props) => {
  const { selectMediaFile, selected, item, deleting } = props;

  return (
    <div className={styles.item}>
      <div className={cx(styles.imageWrapper, { [styles.active]: selected })} onClick={() => selectMediaFile()}>
        <img className={styles.image} src={item.file_key} alt={item.file_name} draggable={false} />
        {selected && <Icon icon="check_blue" className={styles.checkmark} />}
        {deleting && (
          <div className={cx(styles.spinnerWrapper, styles.deleteSpinner)}>
            <Spinner white className={styles.spinner} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaFileImage;
