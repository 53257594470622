import { createSelector } from 'reselect';
import { CampaignsState, CampaignsStoreEnum } from './types';
import { Store } from '../types';

export const campaignsSelector = (store: Store) => store.campaigns;
export const campaignsListSelector = createSelector(campaignsSelector, (campaigns: CampaignsState) =>
  campaigns.list.asMutable({ deep: true })
);
export const deleteCampaignsSelector = createSelector(campaignsSelector, (campaigns: CampaignsState) =>
  campaigns.deletedCampaigns.asMutable({ deep: true })
);
export const newsSelector = createSelector(campaignsSelector, (campaigns: CampaignsState) =>
  campaigns.news.asMutable({ deep: true })
);
export const campaignsLoadedSelector = createSelector(
  campaignsSelector,
  (campaigns: CampaignsState) => campaigns.loaded
);
export const campaignsMetaSelector = createSelector(campaignsSelector, (campaigns: CampaignsState) =>
  campaigns.meta.asMutable({ deep: true })
);
export const oneCampaignAnalyticsSelector = createSelector(campaignsSelector, (campaigns: CampaignsState) =>
  campaigns.one.asMutable({ deep: true })
);
export const oneCampaignAnalyticsPlayersActivitySelector = createSelector(
  campaignsSelector,
  (campaigns: CampaignsState) => campaigns.playersActivity.asMutable({ deep: true })
);
export const oneCampaignAnalyticsGameplaysSelector = createSelector(campaignsSelector, (campaigns: CampaignsState) =>
  campaigns.gameplays.asMutable({ deep: true })
);
export const buttonsAndTrafficsSelector = createSelector(campaignsSelector, (campaigns: CampaignsState) =>
  campaigns.buttonsAndTraffics.asMutable({ deep: true })
);
export const activitiesSelector = createSelector(campaignsSelector, (campaigns: CampaignsState) =>
  campaigns.activities.asMutable({ deep: true })
);
export const topPlayersSelector = createSelector(campaignsSelector, (campaigns: CampaignsState) =>
  campaigns.oneTop.asMutable({ deep: true })
);
export const dailyStatisticsSelector = createSelector(campaignsSelector, (campaigns: CampaignsState) =>
  campaigns.oneDailyStatistics.asMutable({ deep: true })
);
export const oneDayStatisticsSelector = createSelector(campaignsSelector, (campaigns: CampaignsState) =>
  campaigns.oneDayStatistics.asMutable({ deep: true })
);
export const dailyEmailStatisticsSelector = createSelector(campaignsSelector, (campaigns: CampaignsState) =>
  campaigns[CampaignsStoreEnum.DAILY_EMAIL_STATISTICS]?.asMutable({ deep: true })
);
export const emailStatisticsSelector = createSelector(campaignsSelector, (campaigns: CampaignsState) =>
  campaigns[CampaignsStoreEnum.EMAIL_STATISTICS]?.asMutable({ deep: true })
);
export const leaderboardDataSelector = createSelector(campaignsSelector, (campaigns: CampaignsState) =>
  campaigns[CampaignsStoreEnum.LEADERBOARD_DATA]?.asMutable({ deep: true })
);
export const resultsDataSelector = createSelector(campaignsSelector, (campaigns: CampaignsState) =>
  campaigns[CampaignsStoreEnum.RESULTS_DATA]?.asMutable({ deep: true })
);
export const statusesSelector = createSelector(campaignsSelector, (campaigns: CampaignsState) =>
  campaigns.statuses.asMutable({ deep: true })
);
