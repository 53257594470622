import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { message, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { accountSelector, authSelector } from '../store/auth/selectors';
import {
  logout as signOut,
  setActiveBrand,
  setActiveBrandId,
  setAuthField,
  setPaymentSubscriptionExpiresAt,
  setUser,
  updateAccountAction,
  updateUser,
  updateUserField
} from '../store/auth/actions';
import {
  BrandForFilter,
  ChangePasswordPayloadType,
  CreateAccountPayloadType,
  ForgotPasswordPayloadType,
  PasswordConformationPayloadType,
  SaveNewPasswordPayloadType,
  SignInPayloadType,
  UpdateAccountCompanyPayloadType,
  Submit2faCodeType,
  Resend2faCodeType,
  UpdateAccountPayload,
  UpdateAccountProfilePayloadType,
  HubspotTicketPayloadType,
  LoginResponseType
} from '../utils/type';
import { clearCampaigns } from '../store/campaigns/actions';
import useApi from './useApi';
import addyConfirm from '../components/confirm';
import useIntegration from './integrations/useIntegration';
import { hasFullAccess } from '../utils/helpers/hasFullAccess';
import { UserRoles, AccountType } from '../utils/enums';
import { AuthField } from '../store/auth/types';
import { ErrorsType, useErrors } from '../utils/hooks';
import useCurrentUser from '../utils/hooks/useCurrentUser';
import { assignCurrentAccountIdToPayload } from '../utils/helpers/assignCurrentAccountIdToPayload';
import * as T from '../utils/type';

interface iAuth {
  logout: () => void;
  errors: ErrorsType;
  login: (data: SignInPayloadType, from?: string) => void;
  join: (data: CreateAccountPayloadType) => void;
  updateProfile: (data: UpdateAccountProfilePayloadType) => void;
  updateAccountCompanyInfo: (data: UpdateAccountCompanyPayloadType, silent?: boolean, cb?: () => void) => void;
  updateAccount: (data: UpdateAccountPayload) => void;
  signInByToken: () => void;
  signUp: (data: PasswordConformationPayloadType) => void;
  setExpiredSubscriptionDate: (expiredAt: string) => void;
  changePassword: (data: ChangePasswordPayloadType, cb: () => void) => void;
  forgotPassword: (data: ForgotPasswordPayloadType) => void;
  resetPassword: (data: SaveNewPasswordPayloadType) => void;
  busy: boolean;
  createHubSpotTicket: (data: HubspotTicketPayloadType, cb: () => void) => void;
  submit2faCode: (payload: Submit2faCodeType) => void;
  resend2faCode: (payload: Resend2faCodeType) => Promise<boolean>;
  handleLoginResponse: (data: LoginResponseType, redirect?: boolean, from?: string) => void;
  switchAccount: (accountId: number) => void;
}

const useAuth = (): iAuth => {
  const dispatch = useDispatch();
  const { clearIntegration } = useIntegration();
  const history = useHistory();
  const auth = useSelector(authSelector);
  const account = useSelector(accountSelector);
  const { user } = useCurrentUser();

  const api = useApi();
  const [busy, setBusy] = useState<boolean>(false);
  const { errors, clearErrors, parseErrors } = useErrors();

  const logout = () => {
    dispatch(clearCampaigns());
    dispatch(signOut());
    clearIntegration();
    history.push('/login');
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('refresh_token_expired_at');
  };

  const handleLoginResponse = (data: any, redirect?: boolean, from?: string) => {
    if (data.twoFactorAuth) {
      logout();
      dispatch(setAuthField(data, AuthField.TWO_FACTOR_AUTH));
      history.push('/two-factor');
      return;
    }

    dispatch(setUser(data));
    localStorage.setItem('refresh_token', data.refresh_token);
    localStorage.setItem('refresh_token_expired_at', data.refresh_token_expired_at);

    if ([UserRoles.ADMIN, UserRoles.SUPER_ADMIN].includes(data.role)) {
      if (redirect) history.push(from ? from : '/admin/campaigns');
      return;
    }

    localStorage.setItem('accountId', data.account.id);

    const hasAccessToAllBrands = hasFullAccess(data);

    let activeBrand;
    let activeBrandId: number = Number(localStorage.getItem('activeBrandId'));
    if (activeBrandId) {
      activeBrand = data.account[hasAccessToAllBrands ? 'brands' : 'brandsForFilter']?.find(
        (item: BrandForFilter) => item.id === activeBrandId
      );
    }

    if (!activeBrand) {
      activeBrand = hasAccessToAllBrands ? data.account.brands[0] : data.brandsForFilter[0];
      activeBrandId = activeBrand?.id;
    }

    if (activeBrandId) {
      dispatch(setActiveBrandId(activeBrandId));
      dispatch(setActiveBrand(activeBrand));
      moment.tz.setDefault(activeBrand.timezone);
    }

    if (redirect) {
      history.push(activeBrandId ? from || '/campaigns' : '/create-brand');
    }
  };

  const login = (data: SignInPayloadType, from?: string) => {
    clearErrors();
    setBusy(true);

    assignCurrentAccountIdToPayload<SignInPayloadType>(data);

    api
      .signIn(data)
      .then((response: AxiosResponse) => {
        setBusy(false);
        if (response.data.accessToken) {
          localStorage.setItem('token', response.data.accessToken);
        }
        handleLoginResponse(response.data, true, from);
      })
      .catch((error: AxiosError) => {
        setBusy(false);
        parseErrors(error, 'Authorization error');
      });
  };

  const signUp = (data: PasswordConformationPayloadType) => {
    clearErrors();
    setBusy(true);
    api
      .signUp(data)
      .then((response: AxiosResponse) => {
        setBusy(false);
        localStorage.setItem('token', response.data.accessToken);
        localStorage.setItem('refresh_token', response.data.refresh_token);
        localStorage.setItem('refresh_token_expired_at', response.data.refresh_token_expired_at);
        dispatch(setUser(response.data));
        if ([UserRoles.ADMIN, UserRoles.SUPER_ADMIN].includes(response.data.role)) {
          history.push('/admin/campaigns');
          return;
        }

        const firstBrand = hasFullAccess(response.data)
          ? response.data.account.brands[0]
          : response.data.brandsForFilter[0];

        if (
          !firstBrand?.id &&
          (response.data.account.has_active_license ||
            [AccountType.FREE, AccountType.STAFF].includes(response.data.account?.type))
        ) {
          history.push('/create-brand');
        } else {
          if (firstBrand?.id) {
            dispatch(setActiveBrandId(firstBrand?.id));
            dispatch(setActiveBrand(firstBrand));

            history.push('/campaigns');
          } else {
            history.push('/account-expired');
          }
        }
      })
      .catch((error: AxiosError) => {
        setBusy(false);
        parseErrors(error, 'Authorization error');
      });
  };

  const forgotPassword = (data: ForgotPasswordPayloadType) => {
    setBusy(true);
    api
      .forgotPassword(data)
      .then(() => {
        notification.info({
          message: 'Authorization info',
          description: `Email with instructions was sent to ${data.email}`
        });
        setBusy(false);
      })
      .catch((error: AxiosError) => {
        setBusy(false);
        notification.error({
          message: 'Authorization error',
          description: error.response?.data?.message
        });
      });
  };

  const updateProfile = (data: UpdateAccountProfilePayloadType) => {
    setBusy(true);
    clearErrors();
    api
      .updateProfile(data)
      .then((response: AxiosResponse) => {
        const correctData = {
          ...user,
          full_name: response.data.full_name,
          phone_number: response.data.phone_number
        };

        setBusy(false);
        dispatch(updateUser(correctData));
        message.success('Your profile was successfully updated');
      })
      .catch((error: AxiosError) => {
        setBusy(false);
        parseErrors(error);
      });
  };

  const updateAccount = (data: UpdateAccountPayload) => {
    api.updateAccount(data);
  };

  const updateAccountCompanyInfo = (data: UpdateAccountCompanyPayloadType, silent?: boolean, cb?: () => void) => {
    clearErrors();
    setBusy(true);
    api
      .updateAccountCompanyInfo(data)
      .then((response: AxiosResponse) => {
        dispatch(
          updateAccountAction({
            ...response.data,
            payment_customer_id: 'newly_created'
          })
        );
        dispatch(
          updateUserField({
            phone_number: data.phone_number
          })
        );

        setBusy(false);
        message.success('Company information was successfully updated');
        if (data.company_name !== account.company_name && !silent) {
          addyConfirm({
            title: 'One moment..',
            content:
              'Updating Account title will affect on your Zapier integration. Please do not forget to update this field in the integration settings'
          });
        }
        if (cb) cb();
      })
      .catch((error: AxiosError) => {
        setBusy(false);
        parseErrors(error);
      });
  };

  const resetPassword = (data: SaveNewPasswordPayloadType) => {
    setBusy(true);
    api
      .saveNewPassword(data)
      .then(() => {
        notification.info({
          message: 'Authorization info',
          description: 'Password was updated. Please, login'
        });
        setBusy(false);
        history.push('/login');
      })
      .catch((error: AxiosError) => {
        setBusy(false);
        parseErrors(error, 'Authorization error');
      });
  };

  const signInByToken = () => {
    const payload: T.SignInByTokenPayloadType = { refreshToken: auth.refresh_token as string };
    assignCurrentAccountIdToPayload<T.SignInByTokenPayloadType>(payload);

    api
      .signInByRefreshToken(payload)
      .then((response: AxiosResponse<LoginResponseType>) => {
        handleLoginResponse(response.data, false);
      })
      .catch((error: AxiosError) => {
        logout();
        notification.error({
          message: 'Authorization error',
          description: error.response?.data?.message
        });
      });
  };

  const changePassword = (data: ChangePasswordPayloadType, cb: () => void) => {
    clearErrors();
    setBusy(true);
    api
      .changePassword(data)
      .then(() => {
        clearErrors();
        setBusy(false);
        notification.success({
          message: 'Authorization info',
          description: 'Password was successfully changed'
        });
        cb();
      })
      .catch((error: AxiosError) => {
        setBusy(false);
        parseErrors(error, 'Authorization error');
      });
  };

  const join = (data: CreateAccountPayloadType) => {
    clearErrors();
    setBusy(true);
    api
      .join(data)
      .then(() => {
        notification.success({
          message: 'Authorization info',
          description: 'Check your email to continue'
        });
        setBusy(false);
        history.push('/join?emailSent=true');
      })
      .catch((error: AxiosError) => {
        setBusy(false);
        parseErrors(error, 'Authorization error');
      });
  };

  const setExpiredSubscriptionDate = (expiredAt: string) => {
    dispatch(setPaymentSubscriptionExpiresAt(expiredAt));
  };

  const createHubSpotTicket = (data: HubspotTicketPayloadType, cb: () => void) => {
    setBusy(true);
    api
      .createHubSpotTicket(data)
      .then(() => {
        setBusy(false);
        addyConfirm({
          title: 'Support ticket has been sent!',
          content:
            'We will get back to you as soon as possible on the e-mail registered on your account. If you need urgent assistance with any of the campaigns which are currently live then please contact your customer success manager directly.',
          icon: 'mail'
        });
        cb();
      })
      .catch(() => {
        setBusy(false);
      });
  };

  const submit2faCode = (payload: Submit2faCodeType) => {
    setBusy(true);

    assignCurrentAccountIdToPayload<Submit2faCodeType>(payload);

    api
      .submit2faCode(payload)
      .then((response) => {
        localStorage.setItem('token', response.data.accessToken);
        handleLoginResponse(response.data, true);
        setBusy(false);
      })
      .catch((error) => {
        message.warn(error.response?.data?.message);
        setBusy(false);
      });
  };

  const resend2faCode = async (payload: Resend2faCodeType) => {
    let success = true;
    try {
      await api.resend2faCode(payload);
      message.success('New verification code has been sent to your email');
    } catch (e) {
      message.warn('Something went wrong');
      success = false;
    }

    return success;
  };

  const switchAccount = async (id: number) => {
    api.switchAccount(id).then((response: AxiosResponse<LoginResponseType>) => {
      localStorage.setItem('token', response.data.accessToken);
      handleLoginResponse(response.data, true);
    });
  };

  return {
    logout,
    setExpiredSubscriptionDate,
    join,
    login,
    signInByToken,
    errors,
    busy,
    updateAccountCompanyInfo,
    changePassword,
    updateProfile,
    resetPassword,
    forgotPassword,
    signUp,
    createHubSpotTicket,
    updateAccount,
    submit2faCode,
    resend2faCode,
    handleLoginResponse,
    switchAccount
  };
};

export default useAuth;
