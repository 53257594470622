import { AxiosResponse } from 'axios';
import { LoginResponseType, SignInByOptimovePayloadType } from '../../utils/type';
import useApi from '../useApi';
import useAuth from '../useAuth';
import { assignCurrentAccountIdToPayload } from '../../utils/helpers/assignCurrentAccountIdToPayload';

interface iOptimoveAuth {
  signInByOptimove: (payload: SignInByOptimovePayloadType) => Promise<boolean>;
}

const useOptimoveAuth = (): iOptimoveAuth => {
  const { handleLoginResponse } = useAuth();
  const api = useApi();

  const signInByOptimove = async (payload: SignInByOptimovePayloadType) => {
    assignCurrentAccountIdToPayload(payload);

    try {
      const response: AxiosResponse<LoginResponseType> = await api.signInByOptimove(payload);
      if (response.data.accessToken) {
        localStorage.setItem('token', response.data.accessToken);
      }

      handleLoginResponse(response.data, true);

      return true;
    } catch (e: any) {
      return false;
    }
  };

  return {
    signInByOptimove
  };
};

export default useOptimoveAuth;
