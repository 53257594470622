import { Option } from '../../type';
import { AccountType } from '../../enums';

export const accountTypeOptions: Option<AccountType>[] = [
  {
    label: 'Free',
    value: AccountType.FREE
  },
  {
    label: 'Standard',
    value: AccountType.STANDARD
  },
  // {
  //   label: 'Agencey',
  //   value: AccountType.AGENCY
  // },
  // {
  //   label: 'Custom',
  //   value: AccountType.CUSTOM
  // },
  {
    label: 'Staff',
    value: AccountType.STAFF
  }
];
