import React, { createContext, FunctionComponent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ceil } from 'lodash';
import { AxiosResponse } from 'axios';
import addyConfirm from '../components/confirm';
import { useHistory } from 'react-router-dom';
import { io } from 'socket.io-client';
import useApi from './useApi';
import { AccountLicense, AccountTierSettings, ExportFileType, UpdatedNowBy } from '../utils/type';
import { UserAccountRoles } from '../utils/enums';
import useCurrentUser from '../utils/hooks/useCurrentUser';
import { useDispatch, useSelector } from 'react-redux';
import { updateAccount } from '../store/auth/actions';
import { AccountPayment } from '../utils/type/payments/accountPayment.type';
import usePurchaseNotifications from './payment/usePurchaseNotifications';
import { PurchaseNotification } from '../utils/type/purchaseNotifications/pruchaseNotification.type';
import { setAdminAccount } from '../store/admin/actions';
import { adminAccountSelector } from '../store/admin/selectors';
import { WsClientEventsEnum } from '../utils/type/ws/ws-client-events.enum';
import { WsServerEventsEnum } from '../utils/type/ws/ws-server-events.enum';

type iUseIO = {
  listenCampaign: (campaignId: string, updatedNowBy: UpdatedNowBy) => void;
  stopListeningCampaign: (campaignId: string, email: string) => void;
  closeMessage: (messageId: string, cb: () => void) => void;
  startExport: (campaignId: number, exportingType: ExportFileType, gameType: string) => void;
  messageConfigs: MessageConfig[];
};

export type MessageConfig = {
  messageType: LimitTypeEnum;
  messageColor: 'red' | 'yellow';
  percent: number;
  messageId: string;
  campaignId?: number;
  campaignTitle?: string;
};

export enum LimitTypeEnum {
  Date = 'date',
  UniqueVisitors = 'uniqueVisitor'
}

type LimitNotificationType = {
  _id: string;
  campaignTitle: string;
  accountId: number;
  campaignId?: number;
  limit: LimitTypeEnum;
  percent: number;
};

type TierSettingUpgrade = {
  accountId: number;
  tierSettings: Partial<AccountTierSettings>;
  paymentLicense?: Pick<AccountLicense, 'type'>;
  accountPayments?: AccountPayment[];
};

const IOContext = createContext<iUseIO>({
  listenCampaign: () => {},
  closeMessage: () => {},
  startExport: () => {},
  messageConfigs: [],
  stopListeningCampaign: () => {}
});

type ProviderProps = {
  children: ReactNode | ReactNode[];
};

export const IOProvider: FunctionComponent<ProviderProps> = ({ children }) => {
  const history = useHistory();
  const { user, isAdmin, account } = useCurrentUser();
  const { addNotification, getPurchaseNotifications } = usePurchaseNotifications();
  const editableAccount = useSelector(adminAccountSelector);
  const dispatch = useDispatch();
  const api = useApi();
  const [messageConfigs, setMessageConfigs] = useState<MessageConfig[]>([]);

  const defaultUpdatedNowBy = {
    email: '',
    role: UserAccountRoles.ACCOUNT_OWNER,
    fullName: ''
  };

  useEffect(() => {
    if (user.id && !isAdmin) {
      if (user.accountPermissions.role === UserAccountRoles.ACCOUNT_OWNER) {
        getPurchaseNotifications();
      }

      api.getAccountLimits().then((response: AxiosResponse) => {
        let messages: MessageConfig[] = [];
        // const expiredDate = moment(user.account.payment_expires_at);
        // const accountExpired = moment(expiredDate).isBefore(moment());
        // const showCustomDateMessage =
        //   user.account.type === AccountType.CUSTOM &&
        //   !user.hasPendingLicense &&
        //   expiredDate.subtract(4, 'days').isBefore(moment());
        //
        // if (showCustomDateMessage && !accountExpired) {
        //   messages.push({
        //     messageColor: 'red',
        //     messageType: LimitTypeEnum.Date,
        //     percent: 110,
        //     messageId: 'custom_date',
        //     campaignTitle: ''
        //   });
        // }

        if (response.data?.length > 0) {
          const accountMessages = response.data
            .filter((item: LimitNotificationType) => item.limit === LimitTypeEnum.UniqueVisitors)
            .filter((item: LimitNotificationType) => !item.campaignId || (item.campaignId && item.campaignTitle))
            .map((item: LimitNotificationType) => ({
              messageColor: item.percent >= 110 ? 'red' : 'yellow',
              messageType: item.limit,
              percent: ceil(item.percent),
              messageId: item._id,
              campaignTitle: item.campaignTitle,
              campaignId: item.campaignId
            }));
          messages = [...messages, ...accountMessages];
        }
        setMessageConfigs(messages);
      });
    }
  }, [user.id]);

  const [campaignId, onChangeCampaignId] = useState<string>();
  const [updatedNowBy, onChangeUpdatedNowBy] = useState<UpdatedNowBy>(defaultUpdatedNowBy);
  const socket = useMemo(
    () =>
      io(process.env.REACT_APP_API_URL as string, {
        reconnectionDelayMax: 10000,
        autoConnect: false
      }),
    []
  );

  const handleNewPurchaseNotification = (args: PurchaseNotification) => {
    if (account.id === args.accountId && user.userAccountRole === UserAccountRoles.ACCOUNT_OWNER) addNotification(args);
  };

  const handleTierSettingsUpdated = (args: TierSettingUpgrade) => {
    if (account.id === args.accountId) {
      const payload = {
        paymentLicenses: [args.paymentLicense],
        payments: args.accountPayments,
        tierSettings: {
          ...account.tierSettings,
          ...args.tierSettings
        }
      };

      if (args.paymentLicense) payload.paymentLicenses = [args.paymentLicense];
      if (args.accountPayments) payload.payments = args.accountPayments;

      dispatch(updateAccount(payload));
    } else if (isAdmin && editableAccount.id === args.accountId) {
      dispatch(
        setAdminAccount({
          ...editableAccount,
          tierSettings: args.tierSettings
        })
      );
    }
  };

  const handleStripeCustomerCreated = (args: TierSettingUpgrade) => {
    if (isAdmin && editableAccount.id === args.accountId) {
      dispatch(
        setAdminAccount({
          ...editableAccount,
          ...args
        })
      );
    }
  };

  useEffect(() => {
    if (user.id) {
      socket.connect();
      socket.on(WsServerEventsEnum.NewPurchaseNotification, handleNewPurchaseNotification);
      socket.on(WsServerEventsEnum.tierSettingUpdated, handleTierSettingsUpdated);
      socket.on(WsServerEventsEnum.StripeCustomerCreated, handleStripeCustomerCreated);

      return () => {
        socket.off(WsServerEventsEnum.NewPurchaseNotification, handleNewPurchaseNotification);
        socket.off(WsServerEventsEnum.tierSettingUpdated, handleTierSettingsUpdated);
        socket.off(WsServerEventsEnum.StripeCustomerCreated, handleStripeCustomerCreated);
        socket.disconnect();
      };
    }
  }, [user.id, socket, editableAccount.id]);

  useEffect(() => {
    const listenedByCurrentUser =
      (user.email === updatedNowBy.email || updatedNowBy.email === '') && socket.connected && !!campaignId;
    if (listenedByCurrentUser) {
      document.addEventListener('visibilitychange', onBlur);
    }
    return () => {
      document.removeEventListener('visibilitychange', onBlur);
    };
  }, [socket.connected, campaignId, user.email, updatedNowBy.email]);

  const onBlur = useCallback(() => {
    if (socket.connected && !document.hasFocus()) {
      const blurTime = new Date().valueOf();
      const intervalId = setInterval(() => {
        const currentTime = new Date().valueOf();
        const blurredTime = (currentTime - blurTime) / 1000;
        if (document.visibilityState === 'visible') {
          clearInterval(intervalId);
        } else if (blurredTime > 900) {
          onChangeCampaignId(undefined);
          onChangeUpdatedNowBy(defaultUpdatedNowBy);
          const route = isAdmin ? `/admin/campaigns?redirectId=${campaignId}` : `/campaigns?redirectId=${campaignId}`;
          history.push(route);
        }
      }, 1000);
    }
  }, [socket.connected, defaultUpdatedNowBy, isAdmin, campaignId, history]);

  const listenCampaign = useCallback(
    (campaignId: string, updatedNowBy: UpdatedNowBy) => {
      onChangeCampaignId(campaignId);
      onChangeUpdatedNowBy(updatedNowBy);
      if (updatedNowBy.email === '') {
        const args = {
          campaignId,
          email: user.email,
          role: user.role,
          fullName: user.full_name
        };
        socket.emit(WsClientEventsEnum.StartCampaignUpdate, args);
      }
    },
    [socket, user]
  );

  const stopListeningCampaign = useCallback(
    (campaignId: string, email: string) => {
      socket.emit(WsClientEventsEnum.EndCampaignUpdate, { campaignId, email });
      onChangeCampaignId(undefined);
      onChangeUpdatedNowBy(defaultUpdatedNowBy);
    },
    [defaultUpdatedNowBy, socket]
  );

  const closeMessage = useCallback(
    (messageId: string, cb: () => void) => {
      if (messageId !== 'custom_date')
        socket.emit(WsClientEventsEnum.LimitNotificationUserInteraction, { id: messageId, userId: user.id });
      const newMessageConfigs = messageConfigs.filter((item: MessageConfig) => item.messageId !== messageId);
      setMessageConfigs(newMessageConfigs);
      cb();
    },
    [socket, user.id, messageConfigs]
  );

  const startExport = useCallback(
    (campaignId: number, exportingType: ExportFileType, gameType: string) => {
      if (socket.connected) {
        socket.emit('startPlayersExport', {
          campaignId,
          exportingType,
          gameType
        });
        addyConfirm({
          title: 'Cool',
          content:
            'The requested file is being prepared for downloading that will start automatically. Please do not close the tab as this process will take some time.'
        });
        socket.once('playersExportCompleted', (args: { filename: string }) => {
          if (args?.filename) {
            fetch(args.filename)
              .then((response) => response.blob())
              .then((blob) => {
                const link = window.URL.createObjectURL(blob);
                const [fileName] = args.filename.split('/').reverse();
                const a = document.createElement('a');
                a.href = link;
                a.download = fileName;
                a.setAttribute('style', 'display: none');
                a.click();
                socket.off('');
                window.URL.revokeObjectURL(link);

                socket.emit('finishExport', args);
              });
          }
        });
      } else {
        console.log('error');
      }
    },
    [socket]
  );

  const value = {
    listenCampaign,
    closeMessage,
    startExport,
    messageConfigs,
    stopListeningCampaign
  };

  return <IOContext.Provider value={value}>{children}</IOContext.Provider>;
};

export default IOContext;
