import { ImmutableObject } from 'seamless-immutable';
import { Brand, BrandEmail, BrandInList, CampaignTemplate, User, UserInvitation } from '../../utils/type';
import { Pagination } from '../types';
import { TwoFactorAuthType } from '../../utils/type/2fa/twoFactorAuth.type';
import { AuthorizedUser } from '../../utils/type/users/authorizedUser.type';

export type Action = {
  type: string;
  payload: any;
  path?: AuthField;
};

export enum AuthField {
  UNIQUE_VISITORS_COUNT = 'uniqueVisitorsCount',
  TWO_FACTOR_AUTH = 'twoFactorAuth'
}

export type AuthState = ImmutableObject<{
  token: string | null;
  activeBrandId: string | null;
  activeSettingsBrandId: string | null;
  refresh_token: string | null;
  refresh_token_expired_at: string | null;
  user: AuthorizedUser;
  invoices: any;
  teamMembers: User[];
  brands: BrandInList[];
  brandEmails: BrandEmail[];
  invitations: Pagination<UserInvitation>;
  activeBrand: Brand;
  activeSettingsBrand: Brand;
  campaignTemplates: CampaignTemplate[];
  authorized: boolean;
  [AuthField.UNIQUE_VISITORS_COUNT]: number;
  [AuthField.TWO_FACTOR_AUTH]: TwoFactorAuthType | null;
}>;
