import React, { useEffect } from 'react';
import useCurrentUser from '../../utils/hooks/useCurrentUser';
import useBrand from '../../domain/useBrand';
import addyConfirm from '../../components/confirm';
import Typo from '../../components/typo';
import styles from './defaultBrandSettings.module.css';
import { UserAccountRoles } from '../../utils/enums';
import { setUIField } from '../../store/ui/actions';
import { UIStoreEnum } from '../../store/ui/types';
import { useDispatch } from 'react-redux';

const useDefaultBrandSettingsModal = () => {
  const { account, user } = useCurrentUser();
  const { brand } = useBrand();
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setUIField(false, UIStoreEnum.SHOW_ACTIVE_BRAND_EDIT_MODAL));
    dispatch(setUIField(false, UIStoreEnum.SHOW_DEFAULT_BRAND_POPUP));
  };

  useEffect(() => {
    if (
      brand.default_brand &&
      account.paid &&
      [UserAccountRoles.ACCOUNT_OWNER, UserAccountRoles.ACCOUNT_ADMIN].includes(user.userAccountRole)
    ) {
      dispatch(setUIField(true, UIStoreEnum.SHOW_DEFAULT_BRAND_POPUP));
      addyConfirm({
        title: 'You are now Adact Paid user!',
        className: styles.modal,
        content: (
          <>
            <Typo type="p" className={styles.popoverTitle}>
              By making your first purchase you can now:
            </Typo>
            <Typo type="p">Edit your campaigns unlimited amount of time</Typo>
            <Typo type="p" style={{ marginTop: 6 }}>
              Assign your custom brand name
            </Typo>
          </>
        ),
        okText: 'Claim my brand name',
        cancelText: 'I will do this later',
        onOk: () => {
          dispatch(setUIField(true, UIStoreEnum.SHOW_ACTIVE_BRAND_EDIT_MODAL));
          dispatch(setUIField(false, UIStoreEnum.SHOW_DEFAULT_BRAND_POPUP));
        },
        onClose,
        onCancel: onClose
      });
    }
  }, [brand.id, account.id]);
};

export default useDefaultBrandSettingsModal;
